/* eslint-disable no-extend-native */
String.prototype.isUpperCase = function () {
  return this.valueOf().toUpperCase() === this.valueOf()
}

/* eslint-disable no-extend-native */
String.prototype.toTitleCase = function () {
  if (this.length) {
    var sentence = this.split(' ')
    for (var i = 0; i < sentence.length; i++) {
      if (
        sentence[i].length >= 2 &&
        sentence[i][sentence[i].length - 1].replace(/[^a-zA-Z ]/g, '') &&
        sentence[i][sentence[i].length - 1]
          .replace(/[^a-zA-Z ]/g, '')
          .isUpperCase()
      )
        sentence[i] = sentence[i].toUpperCase()
      else if (sentence[i].length > 2)
        sentence[i] =
          sentence[i][0].toUpperCase() + sentence[i].toLowerCase().slice(1)
      else sentence[i] = sentence[i].toLowerCase()
    }
    return sentence.join(' ')
  } else {
    return ''
  }
}
