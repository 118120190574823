import { combineReducers } from 'redux'
import auth from '../../ducks/auth'
import modal from '../../ducks/modal'
import formState from '../../ducks/formState'
import serviceWorker from '../../ducks/serviceWorker'

const RootReducer = combineReducers({
  auth,
  modal,
  serviceWorker,
  formState,
})

export default RootReducer
