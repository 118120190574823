import React, { useRef } from 'react'
import { Checkbox } from '@material-ui/core'
import SimpleList from './SimpleList'
import HttpHandler from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'

const includeRecord = (record, list) => list.some(({ id }) => id === record.id)

const ItemCheckboxWrapper = ({
  item,
  children,
  isSelected,
  setSelected,
  selectOne = false,
}) => {
  const toggleItem = (e) => {
    const { checked } = e.target
    setSelected((prev) => {
      if (checked && !includeRecord(item, prev))
        return selectOne ? [item] : [...prev, item]
      else if (!checked && includeRecord(item, prev))
        return selectOne ? [] : prev.filter(({ id }) => id !== item.id)
      return prev
    })
  }
  return (
    <div className="flex-center-v">
      <div className="mr-4">
        <Checkbox checked={isSelected} onChange={toggleItem} color="primary" />
      </div>
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  )
}

const SelectableList = React.forwardRef(
  (
    {
      itemComponent: ItemComponent,
      selected = [],
      selectOne = false, // Limit selection to one item
      setSelected,
      showSelectAll,
      paginateBy = 5,
      ...props
    },
    initialRef
  ) => {
    const ref = useRef(initialRef)
    return (
      <SimpleList
        ref={ref}
        showPagination
        paginateBy={paginateBy}
        itemComponent={(item) => (
          <ItemCheckboxWrapper
            item={item}
            selectOne={selectOne}
            isSelected={includeRecord(item, selected)}
            setSelected={setSelected}
          >
            <ItemComponent {...item} />
          </ItemCheckboxWrapper>
        )}
        titleRowButtonProps={
          selected?.length
            ? {
                onClick: () => setSelected([]),
                children: 'Deselecionar todos',
              }
            : !selectOne &&
              showSelectAll && {
                onClick: () => {
                  const filters = {
                    ...props.search,
                    paginate_by: 999,
                  }
                  if (ref.current.textFilter)
                    filters.text_filter = ref.current.textFilter
                  HttpHandler.list(
                    props.djangoApp,
                    props.djangoModel,
                    filters
                  ).then(({ data }) => {
                    setSelected(data.data)
                    toast.info(
                      `${data.data?.length || 0} registros selecionados`
                    )
                  })
                },
                children: 'Selecionar todos',
              }
        }
        {...props}
      />
    )
  }
)

export default SelectableList
