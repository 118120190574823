export const LOGIN = 'hall/auth/LOGIN'
export const LOGIN_PERSIST = 'hall/auth/LOGIN_PERSIST'
export const USER_INFO = 'hall/auth/USER_INFO'
export const LOGOUT = 'hall/auth/LOGOUT'
export const VERIFY_TOKEN = 'hall/auth/VERIFY_TOKEN'
export const RESET_PASSWORD = 'hall/auth/RESET_PASSWORD'

export const USER_TYPE = {
  LEAD: 'lead',
  TOTEM: 'totem',
  ASSOCIATE: 'associate',
  RELATIVE: 'relative',
  RESPONSIBLE: 'responsible',
  MEMBER: 'member',
  COLLABORATOR: 'collaborator',
  BIOMETRY_MANAGER: 'biometry_manager',
  FINANCIAL: 'financial',
  MANAGER: 'manager',
  ADMINISTRATOR: 'administrator',
}
