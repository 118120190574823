import React, { useMemo } from 'react'
import { Card, CircularProgress, Icon } from '@material-ui/core'
import useRequest from 'app/hooks/useRequest'
import HttpHandler from 'app/reusable/HttpHandler'
import KPIModal from './KPIModal'
import { useModal } from 'app/ducks/modal/hooks'
import { moneyFormat } from 'app/reusable/utils'

const KPICard = React.memo(
  ({ app, name, disableDetail, itemComponent, params = {} }) => {
    const kpiRequest = useRequest(HttpHandler.kpi, null, [
      app,
      name,
      'count',
      params,
    ])
    const kpiData = kpiRequest.response
    const showModal = useModal()

    const showKPIModal = () =>
      showModal({
        title: kpiData?.title,
        children: (
          <KPIModal
            app={app}
            name={name}
            itemComponent={itemComponent}
            params={params}
          />
        ),
      })

    const value = useMemo(() => {
      if (kpiData?.format === 'money') {
        return moneyFormat(kpiData?.value)
      }
      return kpiData?.value
    }, [kpiData])

    if (kpiRequest.isLoading)
      return (
        <Card elevation={3} className="p-4 text-center">
          <CircularProgress color="secondary" size={20} />
        </Card>
      )
    return (
      <Card
        elevation={3}
        className="p-4 flex-center-v cursor-pointer"
        onClick={disableDetail ? null : showKPIModal}
      >
        <div>
          <Icon className="m-4 text-muted">{kpiData?.icon}</Icon>
        </div>
        <div>
          <p className="m-0 mb-1" style={{ fontSize: 24 }}>
            <b>{value}</b>
          </p>
          <span className="text-muted">{kpiData?.title}</span>
        </div>
      </Card>
    )
  }
)

export default KPICard
