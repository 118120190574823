import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { useDismissModal, useModal } from 'app/ducks/modal/hooks'
import HttpHandler from 'app/reusable/HttpHandler'
import { useToken, useUserInfo } from 'app/ducks/auth/hooks'
import useRequest from 'app/hooks/useRequest'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { authOperations } from 'app/ducks/auth'
import useModalTimer from 'app/hooks/useModalTimer'

const TOTPVerifyBody = ({ qrCodeBase64 }) => {
  const timer = useModalTimer(60 * 5)
  const dismissModal = useDismissModal()
  const dispatch = useDispatch()
  const token = useToken()

  const [code, setCode] = useState(null)

  const submit = () =>
    toast
      .promise(HttpHandler.verifyTOTP(code), {
        pending: 'Verificando código...',
        success: 'Dispositívo cadastrado com sucesso!',
        error: 'Algo deu errado ao cadastrar o dispositívo.',
      })
      .then(() => {
        dispatch(authOperations.getUserInfo(token))
        dismissModal()
      })

  return (
    <div className="w-400 max-w-full text-center mx-auto">
      <img className="my-5 mx-auto block" src={qrCodeBase64} alt="qr" />
      <p>Leia o QR acima com o seu app de preferencia.</p>
      <p>
        Seu código expira em <b>{timer.asClock}</b>
      </p>

      <TextField
        value={code}
        type="number"
        className="w-full text-center"
        placeholder="Digite o código gerado pelo APP"
        onChange={(e) => {
          const value = e.target.value
          setCode(value)
        }}
      />
      <Button
        color="primary"
        variant="contained"
        className="my-3"
        onClick={submit}
      >
        Verificar
      </Button>
    </div>
  )
}

const TOTPMOdalBody = () => {
  const totpRequest = useRequest(HttpHandler.createTOTP, 'totp')

  if (totpRequest.response)
    return (
      <TOTPVerifyBody qrCodeBase64={totpRequest.response.data.base64_qr_code} />
    )

  return (
    <div>
      <img
        className="my-4 mx-auto block w-300 max-w-full"
        src="/assets/images/illustrations/two_factor_authentication.svg"
        alt="two factor authentication"
      />
      <br />
      <h3 className="text-center">Proteja sua conta!</h3>
      <p className="text-center">
        Você é administrador do sistema e não possui autenticação de dois
        fatores ativa.
      </p>
      <Button
        color="primary"
        variant="outlined"
        size="large"
        className="w-full"
        onClick={() => totpRequest.send()}
      >
        Cadastrar autenticação em dois fatores
      </Button>
    </div>
  )
}

const TOTPModal = () => {
  const userInfo = useUserInfo()
  const formRef = useRef()
  const showModal = useModal()

  useEffect(() => {
    if (
      userInfo?.require_totp_to_login === false &&
      userInfo?.company_require_2fa === true
    )
      showModal({
        children: <TOTPMOdalBody formRef={formRef} />,
        disableBackdropClick: true,
        onCancel: null,
        onConfirm: null,
        persistent: true,
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default TOTPModal
