import React from 'react'
import {
  BottomNavigation,
  BottomNavigationAction,
  Icon,
  Fab,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useSettings from 'app/hooks/useSettings'
import { useHistory } from 'react-router-dom'
import { useUserInfo } from 'app/ducks/auth/hooks'
import useRouteInfo from 'app/hooks/useRouteInfo'
import UserTypeSpecific from 'app/components/UserTypeSpecific/UserTypeSpecific'
import { USER_TYPE } from 'app/ducks/auth/types'
import usePhysicalDeviceToken from 'app/hooks/usePhysicalDeviceToken'

const LayoutBottomBar = () => {
  const userInfo = useUserInfo()
  const theme = useTheme()
  const history = useHistory()
  const routeInfo = useRouteInfo()
  const openPhysicalDeviceQr = usePhysicalDeviceToken()
  const { settings, updateSettings } = useSettings()
  const isMdScreen = useMediaQuery(theme.breakpoints?.down('md'))

  const updateSidebarMode = (sidebarSettings) => {
    updateSettings({
      layoutSettings: {
        leftSidebar: {
          ...sidebarSettings,
        },
      },
    })
  }

  const handleSidebarToggle = () => {
    let { layoutSettings } = settings
    let mode

    if (isMdScreen) {
      mode = layoutSettings.leftSidebar.mode === 'close' ? 'mobile' : 'close'
    } else {
      mode = layoutSettings.leftSidebar.mode === 'full' ? 'close' : 'full'
    }

    updateSidebarMode({ mode })
  }

  return (
    <>
      <div style={{ display: 'block', height: '70px' }} />
      <BottomNavigation
        showLabels
        className="elevation-z8"
        style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      >
        <BottomNavigationAction
          label="Menu"
          icon={<Icon>menu</Icon>}
          onClick={handleSidebarToggle}
        />

        {routeInfo?.bottomBar?.centerButton ? (
          <BottomNavigationAction
            {...routeInfo?.bottomBar?.centerButton}
            icon={
              <Fab color="primary" className="mb-4 elevation-z8" size="large">
                {routeInfo?.bottomBar?.centerButton?.icon}
              </Fab>
            }
          />
        ) : (
          <BottomNavigationAction
            label="Home"
            icon={
              <Fab color="primary" className="mb-4 elevation-z8" size="large">
                <Icon>home</Icon>
              </Fab>
            }
            onClick={() => history.replace('/')}
          />
        )}
        <UserTypeSpecific typeExclude={USER_TYPE.TOTEM}>
          {routeInfo?.bottomBar?.rightButton ? (
            <BottomNavigationAction
              showLabel
              {...routeInfo?.bottomBar?.rightButton}
            />
          ) : (
            <BottomNavigationAction
              showLabel
              label={userInfo?.first_name || 'Minha Conta'}
              className="t-nav-personal-info"
              icon={<Icon>person</Icon>}
              onClick={() => history.push('/personal-data')}
            />
          )}
        </UserTypeSpecific>
        <UserTypeSpecific type={USER_TYPE.TOTEM}>
          <BottomNavigationAction
            showLabel
            label="Conectar"
            icon={<Icon>qr_code</Icon>}
            onClick={openPhysicalDeviceQr}
          />
        </UserTypeSpecific>
      </BottomNavigation>
    </>
  )
}

export default LayoutBottomBar
