import { themes } from '../MatxTheme/initThemes'

export const themeVariants = [
  {
    rule: /localhost|127.0.0.1/i,
    theme: 'amsm',
  },
  {
    rule: /(.*).amsm.com.br|(.*)amsm-hml.concordia.dev.br|amsm-dev.concordia.dev.br|lucas-torresans-macbook-air.local/i,
    theme: 'amsm',
  },
  {
    rule: /(.*).actiontreinamento.com.br|(.*)action-hml.concordia.dev.br|action-dev.concordia.dev.br/i,
    theme: 'action',
  },
  {
    rule: /(.*)igorsandi.concordia.dev.br|(.*).igorsandi.com.br/i,
    theme: 'igorsandi',
  },
  {
    rule: /(.*).guilhermelazaretti.com.br/i,
    theme: 'guilhermelazaretti',
  },
  {
    rule: /(.*)bioacademia.concordia.dev.br/i,
    theme: 'bioacademia',
  },
  {
    rule: /(.*)dcfit.concordia.dev.br/i,
    theme: 'dcfit',
  },
  {
    rule: /(.*)cic.concordia.dev.br|(.*).cicsm.com.br/i,
    theme: 'cic',
  },
  {
    rule: /(.*)larissacioato.concordia.dev.br/i,
    theme: 'larissacioato',
  },
]

// UPDATE BELOW CODE
// DOC http://demos.ui-lib.com/matx-react-doc/layout.html
export const MatxLayoutSettings = {
  activeLayout: 'layout1', // layout1, layout2
  activeTheme: null, // View all valid theme colors inside MatxTheme/themeColors.js
  perfectScrollbar: false,

  themes: themes,
  layoutSettings: {
    leftSidebar: {
      show: true,
      mode: 'full', // full, close, compact, mobile,
      bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg', // TODO: Change image
    },
    topbar: {
      show: true,
      fixed: true,
      theme: 'topbar', // View all valid theme colors inside MatxTheme/themeColors.js
    },
  },
}
