import * as types from './types'

const INITIAL_STATE = {
  props: null,
  stack: [],
}

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW:
      if (window.fcWidget) window.fcWidget.hide()
      if (action.payload?.id && state.props?.id === action.payload.id)
        return state

      const newProps = { ...action.payload, __state: {} }
      if (state.props) {
        return {
          props: newProps,
          stack: [...(state.stack || []), state.props],
        }
      }
      return { props: newProps }

    case types.PERSIST:
      if (state.props) {
        return {
          ...state,
          props: {
            ...state.props,
            values: action.payload,
          },
        }
      }
      return state

    case types.DISMISS:
      if (state.stack?.length) {
        if (window.fcWidget) window.fcWidget.hide()
        const newData = state.stack.pop()
        return {
          ...state,
          props: newData,
        }
      }

      if (window.fcWidget) window.fcWidget.show()

      return INITIAL_STATE

    case types.SET_STATE:
      let newState = action.payload
      if (typeof newState === 'function')
        newState = newState(state.props.__state)
      return {
        ...state,
        props: {
          ...state.props,
          __state: newState,
        },
      }

    default:
      return state
  }
}

export default modalReducer
