import React, { useEffect } from 'react'
import { Provider, useSelector } from 'react-redux'
import {
  Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
import { Store } from './redux/Store'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './custom.scss'
import './extensions'
import { isAuthenticated, isUserInRole, wrapLayout } from './auth/utils'
import GlobalModal from './reusable/GlobalModal'
import { useReloadSW } from './ducks/serviceWorker/hooks'
import useCurrentTheme from './hooks/useCurrentTheme'
import GlobalCss from './styles/GlobalCss'
import MatxSuspense from './components/MatxSuspense/MatxSuspense'
import MatxTheme from './components/MatxTheme/MatxTheme'
import InstallationModal from './components/InstallationModal/InstallationModal'
import NoPasswordModal from './components/NoPasswordModal/NoPasswordModal'
import TOTPModal from './components/TOTPModal/TOTPModal'
import useIsMobile from './hooks/useIsMobile'
// import TotemPrintModal from './components/TotemPrintModal/TotemPrintModal'
import { getUserInfo } from './ducks/auth/actions'
import Firebase from 'Firebase'
import 'moment/locale/pt-br'
import { useFeatureFlags, useUserInfo } from './ducks/auth/hooks'
// import InAppMessagesModal from './components/InAppMessagesModal/InAppMessagesModal'

const Offline = React.lazy(() => import('./views/sessions/Offline'))

const AppRoute = ({
  path,
  exact,
  component,
  roles,
  isPublic,
  userCondition,
  allowedOnThemes,
  hideLayout,
}) => {
  const userInfo = useUserInfo()
  const featureFlags = useFeatureFlags()
  const currentTheme = useCurrentTheme()

  const isUserNotAllowed = roles && !isUserInRole(roles)
  const isUserAllowedByCondition =
    !userCondition || userCondition(userInfo, featureFlags)
  const isRouteInTheme = allowedOnThemes
    ? allowedOnThemes.includes(currentTheme)
    : true

  if (!isPublic && !isAuthenticated()) return <Redirect to="/session/signin" />
  if (!isRouteInTheme || isUserNotAllowed || !isUserAllowedByCondition)
    return <Redirect to="/session/404" />

  return (
    <Route
      path={path}
      component={isPublic || hideLayout ? component : wrapLayout(component)}
      exact={exact}
    />
  )
}

const ServiceWorkerUpdateHandler = () => {
  const realoadSw = useReloadSW()
  const { updated: serviceWorkerUpdated } = useSelector(
    ({ serviceWorker }) => serviceWorker
  )

  useEffect(() => {
    if (serviceWorkerUpdated)
      toast.info(
        'Uma nova atualização do seu aplicativo está disponível, clique aqui para iniciar!',
        {
          toastId: 'sw-update',
          autoClose: false,
          onClick: realoadSw,
        }
      )
  }, [serviceWorkerUpdated, realoadSw])

  return null
}

const AppWraper = ({ children }) => {
  return (
    <AppContext.Provider value={{ routes: [] }}>
      <Provider store={Store}>
        <ServiceWorkerUpdateHandler />
        <SettingsProvider>
          <MatxTheme>
            {children}
            <GlobalCss />
          </MatxTheme>
        </SettingsProvider>
      </Provider>
    </AppContext.Provider>
  )
}

const App = () => {
  const isMobile = useIsMobile()
  const isOnline = window.navigator.onLine

  useEffect(() => {
    const state = Store.getState()
    if (state?.auth?.token) Store.dispatch(getUserInfo(state.auth.token))
    Firebase.init()
  })

  if (!isOnline)
    return (
      <AppWraper>
        <MatxSuspense>
          <Offline />
        </MatxSuspense>
      </AppWraper>
    )

  return (
    <AppWraper>
      <ToastContainer
        position={isMobile ? 'bottom-center' : 'top-right'}
        theme="colored"
      />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Router history={history}>
          <GlobalModal />
          <AuthProvider>
            <MatxSuspense>
              <Switch>
                {routes.map((routeProps, i) => (
                  <AppRoute key={i} {...routeProps} />
                ))}
              </Switch>
            </MatxSuspense>
          </AuthProvider>
        </Router>
      </BrowserRouter>
      <InstallationModal />
      {/* <TotemPrintModal /> */}
      <NoPasswordModal />
      <TOTPModal />
      {/* <InAppMessagesModal /> */}
    </AppWraper>
  )
}

export default App
