import { themeVariants } from 'app/components/MatxLayout/settings'

export const getCurrentTheme = () =>
  process.env.REACT_APP_THEME_NAME ||
  themeVariants.find(({ rule }) => rule.test(window.location.hostname))?.theme

const useCurrentTheme = () => {
  const currentTheme = getCurrentTheme()

  return currentTheme
}

export default useCurrentTheme
