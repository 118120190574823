// import "babel-polyfill";
// import cssVars from "css-vars-ponyfill";

import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from './app/App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { serviceWorkerOperations } from 'app/ducks/serviceWorker'
import { Store } from 'app/redux/Store'

require('dotenv').config()
// cssVars();

if (
  process.env.REACT_APP_SENTRY_ENABLE &&
  JSON.parse(process.env.REACT_APP_SENTRY_ENABLE) &&
  !!process.env.REACT_APP_SENTRY_DSN &&
  !!process.env.REACT_APP_SENTRY_ENVIRONMENT
)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.5,
  })

ReactDOM.render(<App />, document.getElementById('root'))

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: (reg) => serviceWorkerOperations.init(reg)(Store.dispatch),
  onUpdate: (reg) => serviceWorkerOperations.update(reg)(Store.dispatch),
})
