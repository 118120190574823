import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    right: 0,
    margin: 'auto',
    textAlign: 'center',
  },
}))

const Loading = (props) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <div className={classes.loading}>
      <img loading="lazy" className="w-180 mb-6" src={theme.logo} alt="" />
      <br />
      <br />
      <CircularProgress />
      <br />
      <br />
      <span className="text-muted mt-6">Carregando...</span>
    </div>
  )
}

export default Loading
