import React, { useEffect, useRef } from 'react'
import { useMediaQuery } from '@material-ui/core'
import Layout1Topbar from './Layout1Topbar'
import Layout1Sidenav from './Layout1Sidenav'
import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import SidenavTheme from '../../MatxTheme/SidenavTheme/SidenavTheme'
import { makeStyles } from '@material-ui/core/styles'
import useSettings from 'app/hooks/useSettings'
import MatxSuspense from 'app/components/MatxSuspense/MatxSuspense'
import LayoutBottomBar from './LayoutBottomBar'
import useIsMobile from 'app/hooks/useIsMobile'
import OpenInvoiceBanner from 'app/components/OpenInvoiceBanner/OpenInvoiceBanner'

const useStyles = makeStyles(() => ({
  contentWrap: ({ width }) => {
    return {
      verticalAlign: 'top',
      marginLeft: width,
      transition: 'all 0.3s ease',
    }
  },
  topbar: {
    top: 0,
    zIndex: 96,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 44%, rgba(247, 247, 247, 0.4) 50%, rgba(255, 255, 255, 0))',
    transition: 'all 0.3s ease',
  },
}))

// TODO: clean this huge mess PLEASE

const Layout1 = ({ children }) => {
  const _isMobile = useIsMobile()
  const { settings, updateSettings } = useSettings()
  const { layoutSettings } = settings
  const {
    leftSidebar: { mode: sidenavMode, show: showSidenav },
  } = layoutSettings

  const getSidenavWidth = () => {
    switch (sidenavMode) {
      case 'full':
        return 'var(--sidenav-width)'
      case 'compact':
        return 'var(--sidenav-compact-width)'
      default:
        return '0px'
    }
  }

  const sidenavWidth = getSidenavWidth()
  let classes = useStyles({ width: sidenavWidth })
  const theme = useTheme()
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))

  const ref = useRef({ isMdScreen, settings })

  const layoutClasses = `theme-${theme.palette.type} flex`

  useEffect(() => {
    let { settings } = ref.current
    let sidebarMode = settings.layoutSettings.leftSidebar.mode
    if (settings.layoutSettings.leftSidebar.show) {
      let mode = isMdScreen ? 'close' : sidebarMode
      updateSettings({ layoutSettings: { leftSidebar: { mode } } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMdScreen])

  // return children

  return (
    <div className={clsx('bg-default', layoutClasses)}>
      {showSidenav && sidenavMode !== 'close' && (
        <SidenavTheme>
          <Layout1Sidenav />
        </SidenavTheme>
      )}

      <div
        className={clsx(
          'flex-grow flex-column relative overflow-hidden h-full-screen',
          classes.contentWrap
        )}
      >
        <Layout1Topbar fixed={true} className="elevation-z8" />
        <div className="flex-grow flex-column relative h-full scroll-y">
          <OpenInvoiceBanner />
          <div className="relative flex-grow">
            <MatxSuspense>{children}</MatxSuspense>
          </div>
        </div>
        {_isMobile && <LayoutBottomBar />}
      </div>
    </div>
  )
}

export default React.memo(Layout1)
