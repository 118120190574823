import React from 'react'
import { useModal } from 'app/ducks/modal/hooks'
import HttpHandler from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'
import useModalTimer from 'app/hooks/useModalTimer'

const TotemQrCode = ({ qrCode }) => {
  const timer = useModalTimer(60 * 5)

  return (
    <div className="w-400 max-w-full text-center mx-auto">
      <img className="my-5 mx-auto block" src={qrCode} alt="qr" />
      <p>Leia o QR acima com o seu celular para realizar a impressão.</p>
      <p>
        Seu código expira em <b>{timer.asClock}</b>
      </p>
    </div>
  )
}

const usePhysicalDeviceToken = () => {
  const showModal = useModal()

  const openQr = () =>
    toast
      .promise(HttpHandler.getPhysicalDeviceToken(), {
        pending: 'Obtendo código de impressão...',
        success: `Código de impressão gerado`,
        error: 'Erro ao gerar o código de impressão',
      })
      .then(({ data }) =>
        showModal({
          title: 'Imprimir',
          children: <TotemQrCode qrCode={data.base64} />,
        })
      )

  return openQr
}

export default usePhysicalDeviceToken
