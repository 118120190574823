import { setupFreshchat } from 'app/reusable/freshchat'
import * as types from './types'
const { USER_TYPE } = types

const authReducer = (
  state = JSON.parse(localStorage.getItem(types.LOGIN)),
  action
) => {
  let newState
  switch (action.type) {
    case types.LOGIN:
      return {
        token: action.payload,
      }

    case types.LOGIN_PERSIST:
      return {
        token: action.payload,
      }

    case types.USER_INFO:
      newState = {
        ...state,
        ...action.payload,
      }

      const { userInfo } = action.payload

      if (
        [
          USER_TYPE.COLLABORATOR,
          USER_TYPE.BIOMETRY_MANAGER,
          USER_TYPE.FINANCIAL,
          USER_TYPE.MANAGER,
          USER_TYPE.ADMINISTRATOR,
        ].includes(userInfo.user_type)
      ) {
        const { first_name, last_name, username, email } = userInfo
        setupFreshchat(() => {
          let interval
          const tryToStart = () => {
            if (window.fcWidget) {
              window.fcWidget.user.setProperties({
                externalId: username,
                firstName: first_name,
                lastName: last_name,
                email,
              })
              clearInterval(interval)
            }
          }
          interval = setInterval(tryToStart, 2000)
        })
      }

      localStorage.setItem(types.LOGIN, JSON.stringify(newState))
      return newState

    case types.LOGOUT:
      localStorage.removeItem(types.LOGIN)
      return null

    default:
      return state
  }
}

export default authReducer
