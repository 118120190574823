import { useEffect, useMemo } from 'react'
import useDjangoList from 'app/hooks/useDjangoList'

const FrontendAggregation = ({
  aggregator,
  djangoApp,
  djangoModel,
  search,
}) => {
  const listRequest = useDjangoList(djangoApp, djangoModel)

  useEffect(() => {
    listRequest.setFilters({
      ...search,
      page: 1,
      paginate_by: 9999,
    })
    // eslint-disable-next-line
  }, [search])

  const result = useMemo(() => {
    return aggregator(listRequest.data || [])
    // eslint-disable-next-line
  }, [listRequest.data])

  if (listRequest.isLoading) return 'Carregando...'

  return result
}

export default FrontendAggregation
