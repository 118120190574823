import React from 'react'
import { TextField } from '@material-ui/core'
import { appendRequired } from 'app/reusable/utils'

const fieldParsers = {
  IntegerField: {
    onChange: (v) => (+String(v).replace(/[^\d]/g, '')).toFixed(0),
    value: (v) => (+String(v).replace(/[^\d]/g, '')).toFixed(0),
  },
  FloatField: {
    onChange: (v) => String(v).replace(/[^\d]/g, '') / 100,
    value: (v) => (+v).toFixed(2),
  },
}

const NumberInput = ({
  name,
  value,
  field,
  onChange,
  errors,
  testingClassName,
}) => (
  <TextField
    helperText={errors ? errors.join(', ') : field.help_text}
    name={name}
    onChange={(e) => {
      onChange({
        target: {
          name: e.target.name,
          value: fieldParsers[field.title].onChange(e.target.value),
        },
      })
    }}
    error={errors?.length}
    className={testingClassName}
    fullWidth
    variant="outlined"
    label={appendRequired(field.label, field.required)}
    value={fieldParsers[field.title].value(value || 0)}
    size="small"
    inputProps={{
      maxLength: field.max_length,
    }}
  />
)

export default NumberInput
