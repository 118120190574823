import React, { Fragment, useMemo } from 'react'
import Scrollbar from 'react-perfect-scrollbar'
import { navigations } from 'app/navigations'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import useSettings from 'app/hooks/useSettings'
import { isUserAboveAge, isUserInRole } from 'app/auth/utils'
import useCurrentTheme from 'app/hooks/useCurrentTheme'
import MatxVerticalNav from '../MatxVerticalNav/MatxVerticalNav'
import { Store } from 'app/redux/Store'

const useStyles = makeStyles(({ palette, ...theme }) => ({
  scrollable: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  sidenavMobileOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    background: 'rgba(0, 0, 0, 0.54)',
    zIndex: -1,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}))

const runUserCondition = (condition) => {
  const { auth } = Store.getState()
  return condition(auth?.userInfo, auth?.featureFlags)
}

const shouldRenderNavigation = (route, currentTheme) => {
  const isRouteInTheme = route.allowedOnThemes
    ? route.allowedOnThemes.includes(currentTheme)
    : true

  const rules = [
    !route.roles || isUserInRole(route.roles),
    !route.minimumAge || isUserAboveAge(route.minimumAge),
    isRouteInTheme,
    !route.userCondition || runUserCondition(route.userCondition),
    !route.children ||
      route.children.some((nav) => shouldRenderNavigation(nav, currentTheme)),
  ]

  return !rules.some((r) => !r)
}

const Sidenav = ({ children }) => {
  const classes = useStyles()
  const { updateSettings } = useSettings()

  const currentTheme = useCurrentTheme()

  const filteredNavigations = useMemo(
    () =>
      navigations
        .filter((nav) => shouldRenderNavigation(nav, currentTheme))
        .map((nav) => {
          const children = nav.children?.filter((n) =>
            shouldRenderNavigation(n, currentTheme)
          )

          if (children?.length === 1) {
            // If we are dealing w/ a single child then no need for the dropdown
            return children[0]
          }

          return {
            ...nav,
            children,
          }
        }),
    [currentTheme]
  )

  const updateSidebarMode = (sidebarSettings) =>
    updateSettings({
      layoutSettings: {
        leftSidebar: {
          ...sidebarSettings,
        },
      },
    })

  return (
    <Fragment>
      <Scrollbar
        options={{ suppressScrollX: true }}
        className={clsx('relative px-4', classes.scrollable)}
      >
        {children}
        <MatxVerticalNav items={filteredNavigations} />
      </Scrollbar>

      <div
        onClick={() => updateSidebarMode({ mode: 'close' })}
        className={classes.sidenavMobileOverlay}
      />
    </Fragment>
  )
}

export default Sidenav
