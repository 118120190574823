export const createSyntheticEvent = (name, value) => ({
  target: {
    name,
    value,
  },
})

export const appendRequired = (label, isRequired) => {
  return `${label}${isRequired ? ' *' : ''}`
}

export const TOAST_FEEDBACK = {
  CREATE: {
    pending: 'Criando registro...',
    success: 'Sucesso ao criar registro!',
    error: 'Algo deu errado ao criar o registro.',
  },
  UPDATE: {
    pending: 'Atualizando registro...',
    success: 'Sucesso ao atualizar registro!',
    error: 'Algo deu errado ao atualizar o registro.',
  },
  DELETE: {
    pending: 'Removendo registro...',
    success: 'Sucesso ao remover registro!',
    error: 'Algo deu errado ao remover o registro.',
  },
}

export const moneyFormat = (value) =>
  `R$ ${
    value
      ? parseFloat(value)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, '{thousand}')
          .replace('.', ',')
          .replace(/{thousand}/g, '.')
      : '--'
  }`
