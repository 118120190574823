import { useSelector } from 'react-redux'
import {
  accessTypes,
  featureFlags,
  permissions,
  token,
  userId,
  userInfo,
} from './selectors'

export const useIsAuthenticated = () => !!useSelector(token)
export const useToken = () => useSelector(token)
export const useUserInfo = () => useSelector(userInfo)
export const useFeatureFlags = () => useSelector(featureFlags)
export const useUserAccessTypes = () => useSelector(accessTypes)
export const useUserId = () => useSelector(userId)
export const useUserType = () =>
  useSelector((state) => userInfo(state)?.user_type)
export const useIsUser = (role) =>
  useSelector((state) => {
    if (typeof role === 'string') return userInfo(state)?.user_type === role
    else if (Array.isArray(role))
      return role.includes(userInfo(state)?.user_type)
    else if (role) throw new Error(`Invalid value ${role} for role`)
  })
export const useIsUserStaff = () =>
  useSelector((state) => !!userInfo(state)?.is_staff)
export const useIsUserSuperuser = () =>
  useSelector((state) => !!userInfo(state)?.is_superuser)
export const useHasPermission = (djangoModel, permissionName) =>
  useSelector(
    (state) =>
      djangoModel &&
      !!permissions(state)?.includes(
        `${permissionName}_${djangoModel.replace(/-/g, '')}`
      )
  )
