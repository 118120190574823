import React from 'react'
import { Card, Icon } from '@material-ui/core'
import { useModal } from 'app/ducks/modal/hooks'
import SimpleList from 'app/components/SimpleList/SimpleList'
import ActivityClass from '../gym/ActivityClass'
import KPICard from '../dashboard/KPICard'

const ActivityModalityDetails = ({ id }) => {
  return (
    <>
      <div className="mb-3">
        <KPICard
          disableDetail
          app="activities"
          name="active-activity-modality-users"
          params={{ id }}
        />
      </div>
      <SimpleList
        searchable
        showPagination
        title="Turmas"
        djangoApp="activities"
        djangoModel="activity-class"
        emptyStateText="Nenhuma turma localizada para a modalidade"
        search={{ activity_modality: id }}
        paginateBy={5}
        itemComponent={ActivityClass}
      />
    </>
  )
}
const ActivityModality = ({ showActions, ...m }) => {
  const showModal = useModal()
  return (
    <Card
      className="px-6 py-4 mb-3 flex-center-v flex-between cursor-pointer flex-1"
      onClick={() =>
        showModal({
          title: m.description,
          children: <ActivityModalityDetails id={m.id} />,
        })
      }
    >
      <Icon className="mr-4" fontSize="large">
        sports_soccer
      </Icon>
      <div className="flex-1">
        <div className="card-title">{m.description}</div>
      </div>
    </Card>
  )
}

export default ActivityModality
