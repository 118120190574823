import * as types from './types'
import { toast } from 'react-toastify'
import history from 'history.js'
import HttpHandler from 'app/reusable/HttpHandler'
import * as Sentry from '@sentry/react'
import Firebase from 'Firebase'

export const login =
  (
    username,
    password,
    totpCode,
    persist,
    successCallback = null,
    errorCallback = null
  ) =>
  (dispatch) => {
    return toast
      .promise(HttpHandler.authenticate(username, password, totpCode), {
        pending: 'Realizando login...',
        success: 'Login realizado com sucesso.',
        error: 'Algo deu errado, verifique seu usuário e senha.',
      })
      .then(({ data: { token } }) => {
        dispatch({
          type: persist ? types.LOGIN_PERSIST : types.LOGIN,
          payload: token,
        })
        dispatch(getUserInfo(token, successCallback, errorCallback))
      })
      .catch(() => {
        errorCallback && errorCallback()
      })
  }

export const getUserInfo =
  (token, successCallback = null, errorCallback = null) =>
  (dispatch) =>
    HttpHandler.getUserInfo(token)
      .then(
        ({
          data: {
            user_info,
            permissions,
            access_types,
            has_password,
            feature_flags,
          },
        }) => {
          try {
            Sentry.setUser({
              ...user_info,
              access_types,
            })
            Firebase.setUser(user_info)
          } catch (e) {
            Sentry.captureException(e)
            console.warn('Unable to track user', e)
          }
          dispatch({
            type: types.USER_INFO,
            payload: {
              token,
              userInfo: user_info,
              has_password,
              permissions,
              access_types,
              featureFlags: feature_flags,
            },
          })
          successCallback && successCallback(token)
        }
      )
      .catch(() => {
        errorCallback && errorCallback()
      })

export const logout = () => (dispatch) => {
  dispatch({ type: types.LOGOUT })
  history.replace('/session/signin')
}
