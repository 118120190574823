import moment from 'moment'
import { useEffect, useState } from 'react'

const useTimer = (time) => {
  const [timeLeft, setTimeLeft] = useState(time * 1000)

  useEffect(() => {
    if (timeLeft === 0) return
    const timeout = setTimeout(() => setTimeLeft((t) => t - 1000), 1000)
    return () => clearTimeout(timeout)
  }, [timeLeft])

  const end = () => setTimeLeft(0)

  const reset = () => setTimeLeft(time * 1000)

  return {
    end,
    reset,
    raw: timeLeft / 1000,
    finished: !timeLeft,
    asClock: moment()
      .startOf('day')
      .seconds(timeLeft / 1000)
      .format('mm:ss'),
  }
}

export default useTimer
