import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from '@material-ui/core'
import { Icon } from '@material-ui/core'
import moment from 'moment'
moment.locale('pt-br')

const ActivityClass = ({
  id,
  description,
  teacher,
  start_time,
  week_day,
  is_active,
}) => {
  const activityClassTimeDisplay = `${moment()
    .day(week_day + 1 || 0)
    .format('ddd')} ${moment(start_time, 'HH:mm:ss').format('HH:mm')}`

  return (
    <Link to={`/activity-class-details/${id}`} target="_blank">
      <Card className="px-6 py-4 mb-3 flex-center-v cursor-pointer t-card-class">
        <Icon className="text-muted mr-4" fontSize="large">
          school
        </Icon>
        <div className="flex-between flex-center-v flex-1">
          <div>
            <div className="card-title mb-1">{description}</div>
            {is_active === false && (
              <div className="card-subtitle flex-center-v">
                <div className={`badge badge-error`}>Inativa</div>
              </div>
            )}
            {!!teacher && is_active !== false && (
              <div className="card-subtitle flex-center-v">
                <Icon className="text-muted mb-1" fontSize="small">
                  person
                </Icon>
                &nbsp;{teacher}
              </div>
            )}
          </div>
          <div style={{ whiteSpace: 'nowrap' }}>
            <Icon style={{ transform: 'translateY(6px)' }}>access_time</Icon>{' '}
            {activityClassTimeDisplay}
          </div>
        </div>
      </Card>
    </Link>
  )
}

export default ActivityClass
