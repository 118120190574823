import { Grid } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import Select from './Select'
import SimpleModal from './SimpleModal'
import { useDismissModal } from 'app/ducks/modal/hooks'

const msgTypes = [
  { value: 1, label: 'SMS' },
  { value: 2, label: 'Push' },
  { value: 3, label: 'Email' },
]

const msgGroups = [
  { value: 1, label: 'Todos' },
  { value: 2, label: 'Alunos de escolinha' },
  { value: 3, label: 'Alunos da academia' },
  { value: 4, label: 'Titulares' },
  { value: 5, label: 'Dependentes' },
]

const MessagingModal = () => {
  const dismissModal = useDismissModal()
  const [formData, setFormData] = useState(null)

  const handleFormChange = useCallback(
    ({ target: { name, value } }) => {
      setFormData((prev) => ({
        ...(prev || {}),
        [name]: value,
      }))
    },
    [setFormData]
  )

  const secondMethodOptios = useMemo(
    () => msgTypes.filter(({ value }) => value !== formData?.primaryMethod),
    [formData]
  )

  return (
    <SimpleModal
      title="Envio de mensagens"
      maxWidth="md"
      onCancel={dismissModal}
      onConfirm={() => console.log(formData)}
      open
    >
      <Grid spacing={4} className="m-0 w-full messaging-modal" container>
        <Grid item md={3} className="contact-selects">
          <Select
            label="Método de envio primário"
            options={msgTypes}
            onChange={handleFormChange}
            name="primaryMethod"
            value={formData?.primaryMethod}
          />
          <Select
            label="Método de envio secundário"
            options={secondMethodOptios}
            name="secondaryMethod"
            onChange={handleFormChange}
            value={formData?.secondaryMethod}
          />
          <Select
            label="Grupo para envio"
            name="group"
            options={msgGroups}
            onChange={handleFormChange}
            value={formData?.group}
          />
        </Grid>
        <Grid item md={9}>
          <textarea className="w-full h-full p-2" onChange={handleFormChange} />
        </Grid>
      </Grid>
    </SimpleModal>
  )
}

export default MessagingModal
