import React from 'react'
import { useIsUser, useIsUserStaff } from 'app/ducks/auth/hooks'

const UserTypeSpecific = ({ type, typeExclude, isStaff, children }) => {
  const isUserOfType = useIsUser(type)
  const isUserOfTypeExclude = useIsUser(typeExclude)
  const isUserStaff = useIsUserStaff()

  if (type && isUserOfType) return <>{children}</>
  if (isStaff && isUserStaff) return <>{children}</>
  if (typeExclude && !isUserOfTypeExclude) return <>{children}</>
  return null
}

export default UserTypeSpecific
