import { pathToRegexp } from 'path-to-regexp'
import { useHistory } from 'react-router-dom'
import * as routes from '../views/routes'

const useRouteInfo = () => {
  const history = useHistory()
  const currentRoute = Object.values(routes).find(({ path }) =>
    pathToRegexp(path).test(history.location.pathname)
  )
  return currentRoute
}

export default useRouteInfo
