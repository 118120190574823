export const setupFreshchat = (onLoad) => {
  const eleId = 'freshchat-sdk'
  if (!document.getElementById(eleId)) {
    const e = document.createElement('script')
    e.id = eleId
    e.async = !0
    e.src = '//fw-cdn.com/2266314/2921592.js'
    e.addEventListener('load', onLoad)
    e.setAttribute('chat', 'true')
    document.head.appendChild(e)
  }
}
