import * as types from './types'

export const saveState =
  (app, model, data, embeddedRecords, currentStep) => (dispatch) =>
    dispatch({
      type: types.SAVE_STATE,
      payload: { app, model, data, currentStep, embeddedRecords },
    })

export const clearModelState =
  (app = null, model = null) =>
  (dispatch) => {
    if (app && model)
      dispatch({
        type: types.CLEAR_STATE,
        payload: { app, model },
      })
    else
      throw new Error(
        `Missing information to clear form state: ${JSON.stringify({
          app,
          model,
        })}`
      )
  }

export const clearState = () => (dispatch) =>
  dispatch({ type: types.CLEAR_STATE })
