import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { isMobile } from 'utils'

const useIsMobile = () => {
  const [_isMobile, setIsMobile] = useState(isMobile())

  useEffect(() => {
    const handleEvent = debounce(() => {
      setIsMobile(isMobile())
      console.log('Updating mobile state')
    }, 300)
    window.addEventListener('resize', handleEvent)
    return () => window.removeEventListener('resize', handleEvent)
  })

  return _isMobile
}

export default useIsMobile
