import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  dismissModal,
  persistModalFormValues,
  setModalState,
  showMessagingModal,
  showModal,
  showModalForm,
} from './actions'
import {
  isOpen,
  data,
  values as valuesSelector,
  isForm,
  isMessaging,
  state,
} from './selectors'

export const useIsModalOpen = () => useSelector(isOpen)
export const useIsModalForm = () => useSelector(isForm)
export const useIsMessagingModal = () => useSelector(isMessaging)
export const useModalData = () => useSelector(data)

/**
 * @returns {showModal}
 */
export const useModal = () => {
  const dispatch = useDispatch()
  const _showModal = useCallback(
    (...data) => dispatch(showModal(...data)),
    [dispatch]
  )
  return _showModal
}

/**
 * @returns {dismissModal}
 */
export const useDismissModal = () => {
  const dispatch = useDispatch()
  const _dismissModal = useCallback(
    (...data) => dispatch(dismissModal(...data)),
    [dispatch]
  )
  return _dismissModal
}

/**
 * @returns {showModalForm}
 */
export const useModalForm = () => {
  const dispatch = useDispatch()
  const showModal = useCallback(
    (...data) => dispatch(showModalForm(...data)),
    [dispatch]
  )
  return showModal
}

export const useModalFormValues = () => {
  const values = useSelector(valuesSelector)
  const dispatch = useDispatch()

  const setValues = useCallback(
    (...data) => dispatch(persistModalFormValues(...data)),
    [dispatch]
  )

  return [values, setValues]
}

export const useMessagingModal = () => {
  const dispatch = useDispatch()
  const showModal = useCallback(
    (...data) => dispatch(showMessagingModal(...data)),
    [dispatch]
  )
  return showModal
}

export const useModalState = () => {
  const dispatch = useDispatch()
  const modalState = useSelector(state)
  const _setModalState = useCallback(
    (newState) => dispatch(setModalState(newState)),
    [dispatch]
  )
  return [modalState, _setModalState]
}
