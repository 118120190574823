import React, { useCallback } from 'react'
import { TextField } from '@material-ui/core'
import { appendRequired } from 'app/reusable/utils'

import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { convertFormat } from './dateConvertion'

const TimePickerInput = ({
  name,
  value,
  field,
  onRawChange,
  testingClassName,
}) => {
  const inputFormat = convertFormat(field.input_formats[0])
  const formatValue = useCallback(
    (rawValue) => {
      const momObj = moment(rawValue)
      return momObj.format(inputFormat)
    },
    [inputFormat]
  )
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={testingClassName}>
        <TimePicker
          variant="outlined"
          label={appendRequired(field.label, field.required)}
          value={moment(value, inputFormat)}
          inputFormat="HH:mm"
          onChange={(value) =>
            onRawChange(name, value ? formatValue(value) : value)
          }
          PopperProps={{ className: 'fix-datepicker-scroll' }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" fullWidth />
          )}
          clearable={!field.required}
          clearText="Limpar"
          cancelText="Cancelar"
        />
      </div>
    </LocalizationProvider>
  )
}

export default TimePickerInput
