import React from 'react'
import {
  Icon,
  MenuItem,
  Avatar,
  Hidden,
  IconButton,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import useAuth from 'app/hooks/useAuth'
import useSettings from 'app/hooks/useSettings'
import { authOperations } from 'app/ducks/auth'
import { useDispatch } from 'react-redux'
import MatxMenu from 'app/components/MatxMenu/MatxMenu'
import { useHistory } from 'react-router-dom'
import useIsMobile from 'app/hooks/useIsMobile'

const useStyles = makeStyles(({ palette, ...theme }) => ({
  topbar: {
    top: 0,
    zIndex: 96,
    transition: 'all 0.3s ease',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 44%, rgba(247, 247, 247, 0.4) 50%, rgba(255, 255, 255, 0))',

    '& .topbar-hold': {
      backgroundColor: palette.primary.contrastText,
      height: 80,
      paddingLeft: 18,
      paddingRight: 20,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 14,
        paddingRight: 16,
      },
    },
    '& .fixed': {
      boxShadow: theme.shadows[8],
      height: 64,
    },
  },
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 24,
    padding: 4,
    '& span': {
      margin: '0 8px',
      // color: palette.text.secondary
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 185,
  },
}))

const Layout1Topbar = () => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const { userInfo } = useAuth()

  const { settings, updateSettings } = useSettings()
  const fixed = settings?.layoutSettings?.topbar?.fixed
  const isMdScreen = useMediaQuery(theme.breakpoints?.down('md'))

  const updateSidebarMode = (sidebarSettings) => {
    updateSettings({
      layoutSettings: {
        leftSidebar: {
          ...sidebarSettings,
        },
      },
    })
  }

  const handleSidebarToggle = () => {
    let { layoutSettings } = settings
    let mode

    if (isMdScreen) {
      mode = layoutSettings.leftSidebar.mode === 'close' ? 'mobile' : 'close'
    } else {
      mode = layoutSettings.leftSidebar.mode === 'full' ? 'close' : 'full'
    }

    updateSidebarMode({ mode })
  }

  return (
    <div className={classes.topbar}>
      <div className={clsx({ 'topbar-hold': true, fixed: fixed })}>
        <div className="flex justify-between items-center h-full">
          <div className="flex">
            {!isMobile && (
              <IconButton
                className="hide-on-pc"
                color="primary"
                onClick={handleSidebarToggle}
              >
                <Icon>menu</Icon>
              </IconButton>
            )}
            {!!isMobile && !!theme.logoSymbolTopNav && (
              <img
                src={theme.logoSymbolTopNav}
                className={classes.logo}
                height="40px"
                style={{ margin: '4px' }}
                alt=""
              />
            )}
            {history.action === 'PUSH' && (
              <IconButton color="primary" onClick={() => history.goBack()}>
                <Icon>chevron_left</Icon>
              </IconButton>
            )}
          </div>
          <div className="flex items-center">
            <MatxMenu
              menuButton={
                <div className={[classes.userMenu, 't-user-menu'].join(' ')}>
                  <Hidden xsDown>
                    <span>
                      Olá, <strong>{userInfo?.first_name}</strong>
                    </span>
                  </Hidden>
                  <Avatar className="cursor-pointer" src={userInfo?.picture} />
                </div>
              }
            >
              {!isMobile && (
                <MenuItem
                  onClick={() => history.push('/personal-data')}
                  className={[classes.menuItem, 't-nav-personal-info'].join(
                    ' '
                  )}
                >
                  <Icon> candlestick_chart </Icon>
                  <span className="pl-4">Informações pessoais</span>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => dispatch(authOperations.logout())}
                className={classes.menuItem}
              >
                <Icon> power_settings_new </Icon>
                <span className="pl-4">Sair</span>
              </MenuItem>
            </MatxMenu>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout1Topbar
