import SimpleModal from 'app/components/reusable/SimpleModal'
import React, { useCallback, useEffect } from 'react'
import { FormContextProvider } from 'app/contexts/FormContext'
import { useDjangoFormContext } from 'app/hooks/useDjangoForm'
import FormField from 'app/reusable/form/FormField'
import useDjangoForm from 'app/hooks/useDjangoForm'
import {
  useDismissModal,
  useIsMessagingModal,
  useIsModalForm,
  useIsModalOpen,
  useModalData,
  useModalFormValues,
} from 'app/ducks/modal/hooks'
import MessagingModal from 'app/components/reusable/MessagingModal'
import { CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import useIsMobile from 'app/hooks/useIsMobile'
import { useHasPendingForms } from 'app/ducks/formState/hooks'
import { formStateOperations } from 'app/ducks/formState'
import { useDispatch } from 'react-redux'

const ModalForm = ({ onSubmit, onSuccess, disableBackdropClick }) => {
  const dismissModal = useDismissModal()
  const { loading, ordered_fields, title, data, handleSubmit } =
    useDjangoFormContext()

  const handleFormSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit(data)
      dismissModal()
    } else
      handleSubmit((data) => {
        dismissModal()
        onSuccess?.(data) // eslint-disable-line
      })
  }, [onSubmit, handleSubmit, data, dismissModal, onSuccess])

  return (
    <SimpleModal
      open
      title={title}
      disableBackdropClick={disableBackdropClick}
      onConfirm={handleFormSubmit}
      onCancel={() => dismissModal()}
    >
      {loading && (
        <div
          style={{
            padding: '1rem',
            display: 'block',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {ordered_fields?.map((f) => (
        <div className="mb-2">
          <FormField name={f} />
        </div>
      ))}
    </SimpleModal>
  )
}

const ModalFormContextWrapper = (modalData) => {
  const {
    id,
    app,
    model,
    onSubmit,
    predefinedData,
    onSuccess,
    initialData,
    hideOnUpdate,
    disableBackdropClick,
  } = modalData
  const [values, setValues] = useModalFormValues()
  const context = useDjangoForm({
    id,
    djangoApp: app,
    djangoModel: model,
    predefinedData,
    hideOnUpdate,
    initialData: { ...(values || {}), ...(initialData || {}) },
    onChange: setValues,
  })

  return (
    <FormContextProvider value={context}>
      <ModalForm
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        disableBackdropClick={disableBackdropClick}
      />
    </FormContextProvider>
  )
}

const GlobalModal = () => {
  const dispatch = useDispatch()
  const isModalOpen = useIsModalOpen()
  const isModalForm = useIsModalForm()
  const modalData = useModalData()
  const isMessagingModal = useIsMessagingModal()
  const history = useHistory()
  const dismissModal = useDismissModal()
  const isMobile = useIsMobile()
  const pendingForm = useHasPendingForms()

  useEffect(() => {
    return history.listen((_, action) => {
      if (['PUSH', 'REPLACE', 'POP'].includes(action)) {
        // Dismiss non persistant modals if the user leaves a page
        if (!modalData.persistent && isModalOpen) dismissModal()

        // Prevents the user from leaving a pending form if he uses the physical back button
        if (action === 'POP' && isMobile && pendingForm) history.go(1)
        // Clear the form state if the user leaves a page
        else dispatch(formStateOperations.clearState())
      }
    })
  }, [isModalOpen, pendingForm, modalData]) // eslint-disable-line

  if (!isModalOpen) return null

  if (isMessagingModal) return <MessagingModal />
  if (isModalForm) return <ModalFormContextWrapper {...modalData} />
  return <SimpleModal {...modalData} open />
}

export default GlobalModal
