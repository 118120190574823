import { useCallback, useState } from 'react'
import HttpHandler from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'
import isNil from 'lodash/isNil'
import { useDismissModal, useModal, useModalForm } from 'app/ducks/modal/hooks'
import { TOAST_FEEDBACK } from 'app/reusable/utils'
import { useSavedFormState } from 'app/ducks/formState/hooks'

export const clearFormData = (data) => {
  data = { ...data }
  for (const key in data) {
    if (key.endsWith('__label')) delete data[key]
  }
  return data
}

const useEmbbededRecords = ({ rootApp, rootModel, rootId: initialRootId }) => {
  rootModel = rootModel.replace(/-/g, '_')

  const { embeddedRecords: intialRecords } = useSavedFormState(
    rootApp,
    rootModel
  )

  const openModal = useModal()
  const dismissModal = useDismissModal()
  const openModalForm = useModalForm()

  const [rootId, setRootId] = useState(initialRootId)
  const [headers, setHeaders] = useState([])
  const [records, setRecords] = useState(intialRecords || [])

  const load = useCallback(
    (app, model) => {
      HttpHandler.list(app, model, {
        [rootModel]: rootId ?? 0,
        paginate_by: 99,
      }).then(({ data }) => {
        setHeaders((prev) => {
          const headers = Object.fromEntries(
            Object.entries(data?.header || {}).filter(
              ([key]) => !['id', rootModel].includes(key)
            )
          )
          const currentModelData = prev.find(
            (modelData) => modelData.app === app && modelData.model === model
          )

          if (currentModelData) {
            currentModelData.data = headers
          } else {
            prev.push({
              app,
              model,
              data: headers,
            })
          }
          return [...prev]
        })
        setRecords((prev) => {
          const currentModelData = prev.find(
            (modelData) => modelData.app === app && modelData.model === model
          )

          if (currentModelData) {
            currentModelData.data = rootId ? data?.data : currentModelData.data
          } else {
            prev.push({
              app,
              model,
              data: data?.data || [],
            })
          }
          return [...prev]
        })
      })
    },
    [rootModel, rootId, setHeaders, setRecords]
  )

  const handleForm = useCallback(
    (params) =>
      openModalForm({
        predefinedData: {
          [rootModel]: rootId ?? undefined,
        },
        ...params,
      }),
    [rootModel, rootId, openModalForm]
  )

  const createRecord = useCallback(
    (app, model, data, id, idx) =>
      HttpHandler.create(
        app,
        model,
        clearFormData({ ...data, [rootModel]: id, order: idx })
      ).then(({ data }) => data),
    [rootModel]
  )

  const submit = useCallback(
    (id) =>
      Promise.all(
        records.map(async ({ app, model, data }) => ({
          app,
          model,
          data: await Promise.all(
            data.map(async (_data, idx) => {
              if (!_data.id)
                return {
                  ..._data,
                  id: (await createRecord(app, model, _data, id, idx)).pk,
                }
              else return _data
            })
          ),
        }))
      ),
    [records, createRecord]
  )

  const getHeaders = useCallback(
    (model) =>
      headers?.find(({ model: modelName }) => modelName === model)?.data || {},
    [headers]
  )

  const getListData = useCallback(
    (model) =>
      records
        ?.find(({ model: modelName }) => modelName === model)
        ?.data?.reduce((reduced, record, idx) => {
          record = { ...record }
          for (const key in record) {
            if (key.endsWith('__label')) {
              record[key.replace('__label', '')] = record[key]
              delete record[key]
            }
          }
          record.__embbeded_idx = idx
          reduced.push(record)
          return reduced
        }, []),
    [records]
  )

  const handleAdd = useCallback(
    (app, model, data) => {
      if (rootId) {
        toast.promise(
          createRecord(app, model, data, rootId).then(({ form }) => {
            if (form?.errors && Object.entries(form.errors).length) {
              Object.entries(form?.errors).map(([field, e]) =>
                toast.warn(`${field} - ${e}`)
              )
              throw new Error(
                `Erro ao criar o registro vinculado - ${form.title}`
              )
            }
            load(app, model)
          }),
          TOAST_FEEDBACK.CREATE
        )
      } else {
        const existingFormIdx = records?.findIndex(
          ({ model: modelName }) => model === modelName
        )

        if (!isNil(existingFormIdx))
          setRecords((prev) => {
            prev[existingFormIdx].data.push(data)
            return [...prev]
          })
        else
          setRecords((prev) => [
            ...(prev || []),
            {
              app,
              model,
              data: [data],
            },
          ])
      }
    },
    [records, rootId, load, createRecord]
  )
  const add = useCallback(
    (app, model) =>
      handleForm({
        app,
        model,
        onSubmit: (data) => handleAdd(app, model, data),
      }),
    [handleForm, handleAdd]
  )

  const handleUpdate = useCallback(
    (app, model, idx, data) => {
      const modelData = getListData(model)
      const record = modelData[idx]

      if (record.id) {
        toast.promise(
          HttpHandler.update(
            app,
            model,
            clearFormData({ ...data }),
            record.id
          ).then(() => load(app, model)),
          TOAST_FEEDBACK.UPDATE
        )
      } else {
        setRecords((prev) =>
          prev?.map((record) => {
            if (record.app === app && record.model === model) {
              record.data[idx] = data
            }
            return record
          })
        )
      }
    },
    [load, setRecords, getListData]
  )

  const update = useCallback(
    (app, model, idx) => {
      const record = records.find((record) => record.model === model).data[idx]

      handleForm({
        app,
        model,
        id: record.id,
        initialData: record.id ? null : record,
        onSubmit: (data) => handleUpdate(app, model, idx, data),
      })
    },
    [handleForm, handleUpdate, records]
  )

  const batchUpdate = useCallback(
    (app, model, records) => {
      setRecords((prev) => {
        const modelRecord = prev.find(
          (record) => record.app === app && record.model === model
        )

        if (modelRecord) modelRecord.data = records
        else
          prev.push({
            app,
            model,
            data: records,
          })

        return [...prev]
      })
    },
    [setRecords]
  )

  const handleRemove = useCallback(
    (app, model, idx) => {
      const modelData = getListData(model)
      const record = modelData[idx]

      if (record.id) {
        toast.promise(
          HttpHandler.delete(app, model, record.id).then(() =>
            load(app, model)
          ),
          TOAST_FEEDBACK.DELETE
        )
      } else {
        setRecords((prev) =>
          prev?.map((record) => {
            if (record.app === app && record.model === model) {
              record.data.splice(idx, 1)
            }
            return record
          })
        )
      }
    },
    [load, setRecords, getListData]
  )
  const remove = useCallback(
    (app, model, idx) => {
      openModal({
        title: 'Remover',
        children: 'Deseja remover o registro?',
        onConfirm: () => {
          handleRemove(app, model, idx)
          dismissModal()
        },
      })
    },
    [openModal, dismissModal, handleRemove]
  )

  const reorder = useCallback(
    async (model, oldIdx, newIdx) => {
      const modelRecord = records.find((record) => record.model === model)

      const ordered = Array.from(modelRecord.data)
      const [removed] = ordered.splice(oldIdx, 1)
      ordered.splice(newIdx, 0, removed)

      setRecords((prev) => {
        const modelRecordIdx = prev.findIndex(
          (record) => record.model === model
        )

        prev[modelRecordIdx].data = ordered
        return [...prev]
      })

      if (ordered.some(({ id }) => isNil(id))) return

      return await HttpHandler.reorderEmbeddedRecords(
        modelRecord.app,
        modelRecord.model,
        ordered.map(({ id }, order) => ({ id, order }))
      )
    },
    [records, setRecords]
  )

  return {
    headers,
    records,
    add,
    load,
    update,
    batchUpdate,
    remove,
    getHeaders,
    getListData,
    setRootId,
    submit,
    reorder,
    directAdd: handleAdd,
  }
}

export default useEmbbededRecords
