import reducer from './reducers'

import * as modalFormOperations from './operations'
import * as modalFormTypes from './types'
import * as modalFormSelectors from './selectors'
import * as modalFormHooks from './hooks'

export {
  modalFormOperations,
  modalFormTypes,
  modalFormSelectors,
  modalFormHooks,
}

export default reducer
