import React from 'react'
import { Card } from '@material-ui/core'
import useIsMobile from 'app/hooks/useIsMobile'

const SidebarCard = ({ style = {}, className = '', ...props }) => {
  const isMobile = useIsMobile()

  return (
    <Card
      className={['p-6 text-center bg-primary text-white', className].join(' ')}
      elevation={8}
      style={{
        fontSize: 16,
        fontWeight: 'bold',
        width: isMobile ? '450px' : '228px',
        position: 'absolute',
        maxWidth: '85vw',
        zIndex: 999,
        transform: isMobile ? 'translate(-50%, 0)' : null,
        bottom: isMobile ? '90px' : '1rem',
        left: isMobile ? '50%' : '1rem',
        ...style,
      }}
      {...props}
    />
  )
}

export default SidebarCard
