import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import useSettings from 'app/hooks/useSettings'

const useStyles = makeStyles(({ palette, ...theme }) => ({
  brand: {
    padding: '20px 18px 20px 29px',
  },
  hideOnCompact: {
    display: 'none',
  },
  logo: {
    width: '2rem',
  },
}))

const Brand = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { settings } = useSettings()
  const leftSidebar = settings.layoutSettings.leftSidebar
  const { mode } = leftSidebar

  return (
    <div className={clsx('flex items-center justify-between', classes.brand)}>
      <div className="flex items-center">
        <img src={theme.logoSymbol} className={classes.logo} alt="" />
        {/* <MatxLogo /> */}
        <span
          className={clsx({
            'text-18 ml-2 font-medium sidenavHoverShow': true,
            [classes.hideOnCompact]: mode === 'compact',
          })}
        >
          {theme.title}
        </span>
      </div>
      <div
        className={clsx({
          sidenavHoverShow: true,
          [classes.hideOnCompact]: mode === 'compact',
        })}
      >
        {children || null}
      </div>
    </div>
  )
}

export default Brand
