import reducer from './reducers'

import * as authOperations from './operations'
import * as authTypes from './types'
import * as authSelectors from './selectors'
import * as authHooks from './hooks'

export { authOperations, authTypes, authSelectors, authHooks }

export default reducer
