import React, { useCallback, useEffect, useMemo } from 'react'
import { useDjangoFormContext } from 'app/hooks/useDjangoForm'
import ListTable from 'app/components/Tables/ListTable'
import { Button, Card, Icon } from '@material-ui/core'
import { TableCell } from '@material-ui/core'
import OpenFileButton from 'app/components/OpenFileButton/OpenFileButton'

const ActionsColumn = ({ row, onEdit, onDelete }) => (
  <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
    <Button onClick={onEdit} style={{ minWidth: 0 }}>
      <Icon>create</Icon>
    </Button>
    <Button onClick={onDelete} style={{ minWidth: 0 }}>
      <Icon>delete</Icon>
    </Button>
  </TableCell>
)

const EmbeddedForm = ({
  field: { label, app_name: app, model_name: model },
  embeddedFormCustomAdd,
  embeddedFormIsReorderable,
}) => {
  const { embeddedRecords } = useDjangoFormContext()

  const embeddedRecordsListData = useMemo(
    () => embeddedRecords.getListData(model),
    [model, embeddedRecords]
  )

  useEffect(() => {
    embeddedRecords.load(app, model)
  }, []) // eslint-disable-line

  const handleAdd = () =>
    embeddedFormCustomAdd
      ? embeddedFormCustomAdd((data) =>
          embeddedRecords.directAdd(app, model, data)
        )
      : embeddedRecords.add(app, model)

  const handleReorder = useCallback(
    (oldIdx, newIdx) => embeddedRecords.reorder(model, oldIdx, newIdx),
    [embeddedRecords, model]
  )

  return (
    <Card className="p-3" elevation={4}>
      <h6>{label?.toTitleCase?.()}</h6>
      <ListTable
        striped
        reorderable={embeddedFormIsReorderable}
        onReorder={handleReorder}
        headers={{
          ...embeddedRecords.getHeaders(model),
          actions: 'Ações',
        }}
        fields={[...Object.keys(embeddedRecords.getHeaders(model)), 'actions']}
        customCells={{
          file: ({ file }) => (
            <TableCell className="text-center">
              <OpenFileButton file={file} />
            </TableCell>
          ),
          actions: (row) => (
            <ActionsColumn
              row={row}
              onEdit={() =>
                embeddedRecords.update(app, model, row.__embbeded_idx)
              }
              onDelete={() =>
                embeddedRecords.remove(app, model, row.__embbeded_idx)
              }
            />
          ),
        }}
        data={embeddedRecordsListData}
      />
      <Button
        fullWidth
        color="primary"
        variant="outlined"
        className="mt-3"
        onClick={handleAdd}
      >
        <Icon>add</Icon> &nbsp; Adicionar
      </Button>
    </Card>
  )
}

export default EmbeddedForm
