import React, { useEffect, useRef, useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useDismissModal, useModal } from 'app/ducks/modal/hooks'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { toast } from 'react-toastify'
import HttpHandler from 'app/reusable/HttpHandler'
import { useDispatch, useSelector } from 'react-redux'
import { authOperations } from 'app/ducks/auth'
import { useUserInfo } from 'app/ducks/auth/hooks'
import { Button } from '@material-ui/core'
import useIsMobile from 'app/hooks/useIsMobile'

const NoPasswordModalBody = ({ formRef }) => {
  const dispatch = useDispatch()
  const userInfo = useUserInfo()
  const isMobile = useIsMobile()
  const dismissModal = useDismissModal()
  const [formData, setFormData] = useState({
    password: '',
    passwordRepeat: '',
    termsAndPrivacyPolicy: false, // TODO: Save this to the DB
  })

  const handleChange = ({ target: { name, value, checked, type } }) =>
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }))

  const handleSubmit = () => {
    if (formData.password !== formData.passwordRepeat) {
      toast.warn('As senhas não coincidem')
    } else if (!formData.termsAndPrivacyPolicy) {
      toast.warn(
        'É necessário aceitar os termos e a política de privacidade para prosseguir'
      )
    } else {
      toast
        .promise(HttpHandler.changePassword(formData.password), {
          pending: 'Salvando senha...',
          success: `Senha salva com sucesso`,
          error: 'Erro ao salvar a senha',
        })
        .then(({ data }) => {
          dismissModal()
          dispatch(authOperations.getUserInfo(data.token))
        })
    }
  }

  return (
    <div>
      <img
        className="my-4 mx-auto block w-300 max-w-full"
        src="/assets/images/illustrations/security.svg"
        alt="security"
      />
      <br />
      <h3 className="text-center">Bem Vindo!</h3>
      <p className="text-center">
        Seu nome de usuário é <b>{userInfo.username}</b>, anote para quando
        precisar acessar o sistema em outro dispositivo.
      </p>
      <h6 className="text-center">Vamos definir uma senha</h6>
      <br />

      <ul style={{ fontSize: 12 }} className="pl-3 m-0 mb-4">
        <li>
          Sua senha não pode ser muito parecida com o resto das suas informações
          pessoais.
        </li>
        <li>Sua senha precisa conter pelo menos 8 caracteres.</li>
        <li>Sua senha não pode ser uma senha comumente utilizada.</li>
        <li>Sua senha não pode ser inteiramente numérica.</li>
      </ul>
      <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
        <TextValidator
          className="t-field-password mb-3 w-full"
          label="Senha"
          variant="outlined"
          size="small"
          name="password"
          type="password"
          onChange={handleChange}
          value={formData.password}
          validators={['required']}
          errorMessages={['Este campo é obrigatório']}
        />
        <TextValidator
          className="t-field-password mb-3 w-full"
          label="Repita a Senha"
          variant="outlined"
          size="small"
          name="passwordRepeat"
          type="password"
          onChange={handleChange}
          value={formData.passwordRepeat}
          validators={['required']}
          errorMessages={['Este campo é obrigatório']}
        />
        <FormControlLabel
          className="min-w-200"
          name="persistSession"
          control={
            <Checkbox
              size="small"
              name="termsAndPrivacyPolicy"
              onChange={handleChange}
              checked={formData.termsAndPrivacyPolicy}
            />
          }
          label={
            isMobile ? (
              <>Concordo com os Termos de Uso e Política de Privacidade</>
            ) : (
              <>
                Concordo com os{' '}
                <a
                  style={{ color: 'blue' }}
                  href={process.env.REACT_APP_TERMS_AND_PRIVACY_POLICY_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Termos de Uso e Política de Privacidade
                </a>
              </>
            )
          }
        />
        {!!isMobile && (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            href={process.env.REACT_APP_TERMS_AND_PRIVACY_POLICY_URL}
            target="blank"
          >
            Ver o documento
          </Button>
        )}
      </ValidatorForm>
    </div>
  )
}

const NoPasswordModal = () => {
  const auth = useSelector(({ auth }) => auth)
  const formRef = useRef()
  const showModal = useModal()

  useEffect(() => {
    if (auth?.has_password === false)
      showModal({
        children: <NoPasswordModalBody formRef={formRef} />,
        disableBackdropClick: true,
        onCancel: null,
        onConfirm: () => formRef.current.submit(),
        id: 'noPasswordModal',
      })
  }, [auth]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default NoPasswordModal
