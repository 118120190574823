import HttpHandler from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'

const useDeleteRecord = () => {
  const onDelete = ({ app, model, id, description, onSuccess }) => {
    if (
      window.confirm(
        `Deseja excluir o registro ${
          description || ''
        }? Essa ação não poderá ser desfeita`
      )
    )
      toast
        .promise(HttpHandler.delete(app, model, id), {
          pending: 'Excluíndo registro...',
          success: 'Registro excluído!',
          error: 'Erro ao excluir o registro.',
        })
        .then(() => {
          if (onSuccess) onSuccess()
        })
  }
  return onDelete
}

export default useDeleteRecord
