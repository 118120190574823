import React from 'react'
import { Icon } from '@material-ui/core'
import useRequest from 'app/hooks/useRequest'
import HttpHandler from 'app/reusable/HttpHandler'

const OpenInvoiceBanner = () => {
  const pendingDocumentRequest = useRequest(
    HttpHandler._get,
    'user-benefit-points',
    [`finances/api/user-pending-financial-document`]
  )
  const pendingDocumentUrl = pendingDocumentRequest.response?.data?.url

  if (!pendingDocumentUrl) return null

  return (
    <a href={pendingDocumentUrl} target="blank">
      <div className="bg-primary py-3 px-4 text-white flex-center-v">
        <Icon>attach_money</Icon>
        <span className="ml-2">
          A sua fatura está disponível para pagamento &nbsp;
          <b>Ver fatura</b>
        </span>
      </div>
    </a>
  )
}

export default OpenInvoiceBanner
