import { useState, useEffect, useCallback } from 'react'
import HttpHandler from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'
import useRequest from './useRequest'

const useDjangoList = (app, model, initialFilters, hydrate = []) => {
  const listRequest = useRequest(HttpHandler.list)
  const listData = listRequest.response?.data

  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState(initialFilters)
  const [pagination, setPagination] = useState(null)

  const search = useCallback(() => {
    listRequest
      .send(app, model, { page, paginate_by: 10, ...filters }, hydrate)
      .catch((e) => {
        console.error('Erro ao buscar registros.', { e })
        toast.error('Erro ao buscar registros.')
      })
    // eslint-disable-next-line
  }, [app, model, page, filters])

  useEffect(() => {
    search()
  }, [filters, pagination, search])

  return {
    title: listData?.title,
    data: listData?.data,
    header: listData?.header,
    paginator: listData?.paginator,
    paths: {
      create: listData?.create_path,
      update: listData?.update_path,
      delete: listData?.delete_path,
    },
    textFilter: filters?.text_filter,
    filters,
    setTextFilter: (text_filter) =>
      setFilters((prev) => ({ ...prev, text_filter })),
    setFilters,
    setPagination,
    setPage,
    refresh: search,
    isLoading: listRequest.isLoading,
  }
}

export default useDjangoList
