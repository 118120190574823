import React from 'react'
import Layout1 from 'app/components/MatxLayout/Layout1/Layout1'
import { Store } from 'app/redux/Store'
import moment from 'moment'

export const isLoggedUserSuperuser = () => {
  const { auth } = Store.getState()
  return !!auth?.userInfo.is_superuser
}

export const isLoggedUserStaff = () => {
  const { auth } = Store.getState()
  return !!auth?.userInfo.is_staff
}

export const isFromType = (type) => {
  const { auth } = Store.getState()
  return auth?.userInfo.user_type === type
}

export const isAuthenticated = () => {
  const { auth } = Store.getState()
  return !!auth?.token
}

export const UserRoles = {
  ANY: 'any',
  SUPERUSER: 'superuser',
  STAFF: 'staff',
  ASSOCIATE: 'associate',
  RELATIVE: 'relative',
  RESPONSIBLE: 'responsible',
  MEMBER: 'member',
  COLLABORATOR: 'collaborator',
  BIOMETRY_MANAGER: 'biometry_manager',
  FINANCIAL: 'financial',
  ADMINISTRATOR: 'administrator',
  ANY_AUTHENTICATED: 'anyAuthenticated',
}

export const rolesAccessRules = {
  any: () => true,
  superuser: isLoggedUserSuperuser,
  staff: isLoggedUserStaff,
  totem: () => isFromType('totem'),
  associate: () => isFromType('associate'),
  relative: () => isFromType('relative'),
  responsible: () => isFromType('responsible'),
  member: () => isFromType('member'),
  collaborator: () => isFromType('collaborator'),
  biometry_manager: () => isFromType('biometry_manager'),
  manager: () => isFromType('manager'),
  financial: () => isFromType('financial'),
  administrator: () => isFromType('administrator'),
  anyAuthenticated: isAuthenticated,
}

export const wrapLayout = (Component) => {
  return () => (
    <Layout1>
      <Component />
    </Layout1>
  )
}

export const isUserInRole = (roles) =>
  !!roles?.some((role) => rolesAccessRules[role]())

export const isUserAboveAge = (age) => {
  const { auth } = Store.getState()
  const birthDate = auth?.userInfo.birth_date
  return (
    !!birthDate &&
    moment.duration(moment().diff(moment(birthDate))).asYears() > age
  )
}
