import * as types from './types'

const formStateReducer = (state = {}, action) => {
  const stateKey = `${action.payload?.app}-${action.payload?.model}`
  switch (action.type) {
    case types.SAVE_STATE:
      return {
        ...state,
        [stateKey]: {
          data: action.payload.data,
          currentStep: action.payload.currentStep,
          embeddedRecords: action.payload.embeddedRecords,
        },
      }

    case types.CLEAR_STATE:
      if (action.payload) {
        delete state[stateKey]
        return state
      } else return {}

    default:
      return state
  }
}

export default formStateReducer
