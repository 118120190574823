import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { useIsAuthenticated } from 'app/ducks/auth/hooks'
import useIsMobile from 'app/hooks/useIsMobile'
import { useModal } from 'app/ducks/modal/hooks'
import { useTheme } from '@material-ui/core/styles'

import ReactPlayer from 'react-player'
import { isInStandaloneMode, isIos } from 'utils'
import SidebarCard from '../SidebarCard/SidebarCard'

const DISMISS_STORAGE_KEY = 'dismissInstallationModal'

const InstallationModal = () => {
  const theme = useTheme()
  const showIosTutorial =
    theme.iosPwaInstallationHelpVideo && isIos() && !isInStandaloneMode()

  const isAuthenticated = useIsAuthenticated()
  const showModal = useModal()
  const isMobile = useIsMobile()
  const [deferredPrompt, setDeferredPrompt] = useState(null)
  const [visible, setVisible] = useState(showIosTutorial)

  useEffect(() => {
    const beforeInstallPrompt = (e) => {
      if (localStorage.getItem(DISMISS_STORAGE_KEY)) return

      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()

      setDeferredPrompt(e)
      setVisible(true)

      // TODO: send analytics event that PWA install promo was shown.
      console.log(`'beforeinstallprompt' event was fired.`)
    }
    window.addEventListener('beforeinstallprompt', beforeInstallPrompt)

    return () =>
      window.removeEventListener('beforeinstallprompt', beforeInstallPrompt)
  })

  const dismiss = () => {
    setVisible(false)
    localStorage.setItem(DISMISS_STORAGE_KEY, 'true')
  }

  const submitInstall = () => {
    if (showIosTutorial) {
      showModal({
        title: 'Instalando o APP',
        children: (
          <>
            <ReactPlayer
              loop
              muted
              playing
              controls
              width="100%"
              height="50vh"
              url={theme.iosPwaInstallationHelpVideo}
            />
            <p>
              Para salvar o web app no seu dispositivo, siga as instruções do
              vídeo
            </p>
          </>
        ),
      })
    }
    if (!deferredPrompt) {
      console.error('Cannot find deferredPrompt')
      return
    }
    deferredPrompt.prompt()
    deferredPrompt.userChoice.then(({ outcome }) => {
      // TODO: Send analytics
      console.log(`User response to the install prompt: ${outcome}`)
    })
    dismiss()
    setDeferredPrompt(null)
  }

  if (!isAuthenticated || !visible) return null

  return (
    <SidebarCard>
      <p className="mt-0 mb-4">Instale o APP no seu dispositívo</p>
      <Button
        fullWidth={!isMobile}
        variant="text"
        color="secondary"
        size="large"
        className="text-14 py-3 mr-6 text-white"
        onClick={dismiss}
      >
        Agora não
      </Button>
      <Button
        fullWidth={!isMobile}
        variant="contained"
        color="secondary"
        size="large"
        className="text-26 py-1 bg-white text-primary font-semibold"
        onClick={submitInstall}
      >
        Instalar
      </Button>
    </SidebarCard>
  )
}

export default InstallationModal
