import * as types from './types'

const initalState = {
  initialized: false,
  updated: false,
  registration: null,
}

const reducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case types.INIT:
      return {
        ...state,
        initialized: true,
        registration: payload || state.registration,
      }
    case types.UPDATE:
      return {
        ...state,
        updated: true,
        registration: payload || state.registration,
      }
    case types.RELOAD:
      if (state.registration) {
        const registrationWaiting = state.registration.waiting
        if (registrationWaiting) {
          registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
          registrationWaiting.addEventListener('statechange', (e) => {
            if (e.target.state === 'activated') {
              window.location.reload()
            }
          })
        }
      }
      return state
    default:
      return state
  }
}

export default reducer
