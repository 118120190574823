import React from 'react'
import { appendRequired } from 'app/reusable/utils'

const Color = ({ name, value, field, onChange, errors, testingClassName }) => (
  <>
    <label for={name} className="mr-2">
      <b>{appendRequired(field.label, field.required)}</b>
    </label>
    <input
      onChange={onChange}
      required={field.required}
      value={value}
      name={name}
      type="color"
      className={[testingClassName, 'mt-3'].join(' ')}
    />
    <small>{errors ? errors.join(', ') : field.help_text}</small>
  </>
)

export default Color
