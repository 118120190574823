import React from 'react'
import { Card, Icon } from '@material-ui/core'
import ModelActions from '../utilities/ModelActions'
import moment from 'moment'

const ActivityClassUser = ({ showActions, ...u }) => {
  return (
    <Card
      className="px-6 py-4 mb-3 flex-center-v flex-between cursor-pointer flex-1"
      onClick={() =>
        u.activity_class_id &&
        window.open(`/activity-class-details/${u.activity_class_id}`)
      }
    >
      <Icon className="mr-4" fontSize="large">
        sports_soccer
      </Icon>
      <div className="flex-1">
        <div className="card-title">{u.activity_class}</div>
        <small>
          {u.subscription} à {u.subscription_billing_percentage * 100} %
        </small>
        {!!u.exclusion_date && (
          <div className="badge badge-error">
            Excluído em {moment(u.exclusion_date).format('DD/MM/YYYY')}
          </div>
        )}
      </div>
      <div className="flex-center-v flex-between">
        {u.user}
        {!u.exclusion_date && showActions && (
          <ModelActions
            app="activities"
            model="activity-class-user"
            id={u.id}
          />
        )}
      </div>
    </Card>
  )
}

export default ActivityClassUser
