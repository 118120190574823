import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MOBILE_BREAKPOINT } from 'utils'
import useIsMobile from 'app/hooks/useIsMobile'
import { ErrorBoundary } from '@sentry/react'
import { Suspense } from 'react'

const ErrorBoundaryFallback = () => (
  <>
    <img
      className="my-4 mx-auto block w-full max-w-200"
      src="/assets/images/illustrations/error_boundary_fallback.svg"
      alt="fallback"
    />
    <h3>Ops!</h3>
    Não foi possível carregar o conteúdo. Nossa equipe irá verificar o problema.
    <p>Se o problema persistir, entre em contato com o suporte</p>
  </>
)
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles(() => ({
  container: {
    [`@media only screen and (max-width: ${MOBILE_BREAKPOINT})`]: {
      alignItems: 'end',
    },
  },
  paper: {
    [`@media only screen and (max-width: ${MOBILE_BREAKPOINT})`]: {
      margin: '0 1rem',
      width: 'calc(100% - 2rem)',
      borderRadius: '.5rem .5rem 0 0',
    },
  },
}))

const SimpleModal = ({
  open,
  title,
  children,
  onConfirm,
  confirmLabel,
  onCancel,
  cancelLabel,
  onClickOutside,
  disableBackdropClick,
  maxWidth,
}) => {
  const styles = useStyles()
  const isMobile = useIsMobile()

  return (
    <Dialog
      keepMounted
      open={open}
      maxWidth={maxWidth || 'sm'}
      fullWidth={true}
      TransitionComponent={Transition}
      onClose={onClickOutside || onCancel}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableBackdropClick}
      aria-describedby="alert-dialog-slide-description"
      classes={styles}
    >
      {title && <DialogTitle>{title?.toTitleCase?.()}</DialogTitle>}
      <DialogContent>
        <ErrorBoundary fallback={ErrorBoundaryFallback}>
          <Suspense fallback={<div />}>{children}</Suspense>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions className="px-4 py-3">
        {!!onCancel && (
          <Button
            fullWidth={isMobile}
            color="secondary"
            variant="outlined"
            id="modal-cancel-button"
            onClick={onCancel}
          >
            {cancelLabel || 'Voltar'}
          </Button>
        )}
        {!!onConfirm && (
          <Button
            fullWidth={isMobile}
            color="primary"
            variant="contained"
            id="modal-confirm-button"
            onClick={onConfirm}
          >
            {confirmLabel || 'Confirmar'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default SimpleModal
