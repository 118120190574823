import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Button, Dialog, Grid, Icon, Slide } from '@material-ui/core'
import Webcam from 'react-webcam'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Camera = ({
  field: {
    label,
    widget: { input_type },
  },
  name,
  onRawChange,
  value,
}) => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)
  const fileInputRef = useRef()

  const capture = useCallback(() => {
    const imageSrc = ref.current.getScreenshot()
    setOpen(false)
    onRawChange(name, {
      content: imageSrc,
      name: 'teste',
      size: 0,
      type: 'image/png',
    })
  }, [ref, name, onRawChange])

  const handleFilePick = useCallback(
    ({
      target: {
        files: [file],
      },
    }) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        onRawChange(name, {
          content: reader.result,
          name: 'teste',
          size: 0,
          type: 'image/png',
        })
      }
    },
    [onRawChange, name]
  )

  const width = Math.min(window.screen.width / 2, 780)
  const height = width * 0.5625
  const showCamera = useMemo(() => input_type === 'camera', [input_type])

  return (
    <>
      {/* TODO: Use modal hook */}
      <Dialog TransitionComponent={Transition} open={open}>
        <Webcam
          ref={ref}
          audio={false}
          width={width}
          height={height}
          screenshotFormat="image/jpeg"
          style={{ width: '100%', height: '100%' /* , objectFit: 'cover' */ }}
          videoConstraints={{
            width,
            height,
            facingMode: 'environment',
          }}
        />
        <Grid container>
          <Grid item md={6}>
            <Button onClick={() => setOpen(false)} fullWidth size="large">
              &nbsp; Cancelar
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button onClick={capture} fullWidth color="primary" size="large">
              <Icon>photo_camera</Icon>
              &nbsp; Fotografar
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <div
        style={{
          padding: '.5rem',
          borderRadius: '4px',
          border: '1px solid lightgray',
        }}
      >
        <h6>{label?.toTitleCase?.()}</h6>
        <Grid container spacing={2}>
          {showCamera && (
            <Grid item md={6}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => setOpen(true)}
              >
                <Icon>camera_alt</Icon>
                &nbsp; Abrir câmera
              </Button>
            </Grid>
          )}
          <Grid item md={showCamera ? 6 : 12}>
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
              onChange={handleFilePick}
              accept="image/png, image/jpeg"
            />
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => fileInputRef.current.click()}
            >
              <Icon>file_open</Icon>
              &nbsp; Escolher arquivo
            </Button>
          </Grid>
        </Grid>
        {value && (
          <>
            <br />
            {/* TODO: adjust styles */}
            <img
              className={`t-image-preview-${name}`}
              alt=""
              style={{ width: '100%' }}
              src={value.content}
            />
          </>
        )}
      </div>
    </>
  )
}

export default Camera
