import React from 'react'

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const FileField = ({ field: { label }, name, errors, onRawChange }) => {
  const handleChange = (e) => {
    const file = e.target?.files?.[0]

    if (file) {
      // TODO: Show promise toast
      toBase64(file).then(
        (content) => {
          onRawChange(name, {
            name: file.name,
            size: file.size,
            type: file.type,
            content,
          })
        },
        () => {
          // TODO: Show error toast
        }
      )
    } else {
      onRawChange(name, null)
    }
  }

  // TODO: Show curent value
  return (
    <>
      <h6>{label?.toTitleCase?.()}</h6>
      <input type="file" onChange={handleChange} />
      {errors && (
        <>
          <br />
          <small className="text-error">{errors?.join(', ')}</small>
        </>
      )}
    </>
  )
}

export default FileField
