import React from 'react'
import { Button } from '@material-ui/core'
import HttpHandler from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'

const OpenFileButton = ({ file }) => {
  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      onClick={() => {
        toast
          .promise(HttpHandler.getSignedMediaUrl(file), {
            pending: 'Buscando arquivo...',
            success: `Arquivo aberto em nova guia`,
            error: 'Erro ao buscar o arquivo',
          })
          .then(({ data }) => window.open(data.signed_url))
      }}
    >
      Ver arquivo
    </Button>
  )
}
export default OpenFileButton
