import React from 'react'
import { Redirect } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'

import * as userRoutes from './views/routes'
import { useUserType } from './ducks/auth/hooks'

const RootRoute = () => {
  const theme = useTheme()
  const userType = useUserType()
  return <Redirect to={theme.rootRoutes?.[userType] || '/dashboard/default'} />
}

const redirectRoute = [
  {
    path: '/',
    exact: true,
    component: RootRoute,
  },
]

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
]

const routes = [...redirectRoute, ...Object.values(userRoutes), ...errorRoute]

export default routes
