import React, { useState } from 'react'
import { TableCell } from '@material-ui/core'
import DjangoList, { ActionButton } from 'app/components/reusable/DjangoList'
import { Link } from 'react-router-dom'
import UserTypeSpecific from 'app/components/UserTypeSpecific/UserTypeSpecific'
import { USER_TYPE } from 'app/ducks/auth/types'
import moment from 'moment'
import { Divider, Grid } from '@material-ui/core'
import KPICard from '../dashboard/KPICard'
import ActivityClass from '../gym/ActivityClass'
import ActivityClassUser from '../users/ActivityClassUser'
import HttpHandler from 'app/reusable/HttpHandler'
import { useModal } from 'app/ducks/modal/hooks'
import Select from 'app/components/reusable/Select'
import SelectableList from 'app/components/SimpleList/SelectableList'
import { Button } from '@material-ui/core'
import qs from 'qs'
import SimpleList from 'app/components/SimpleList/SimpleList'
import ActivityModality from './ActivityModality'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const printAttendanceList = (params) =>
  window.open(
    `${process.env.REACT_APP_BACKEND_URL}${HttpHandler.getPrintPath(
      'activities',
      'activity-class-print-attendance-list',
      ''
    )}?${qs.stringify(params, {
      arrayFormat: 'repeat',
    })}`
  )
const SchoolAttendanceListFilters = () => {
  const [selected, setSelected] = useState([])
  const [reportCycle, setReportCycle] = useState(moment().format('YYYYMM'))

  const printFilterResults = (selectAll = false) =>
    printAttendanceList({
      cycle: reportCycle,
      selected: selectAll ? 'all' : selected.map(({ id }) => id),
    })

  return (
    <>
      <Select
        fullWidth
        id="report-cycle"
        name="report-cycle"
        value={reportCycle}
        className="mb-3"
        placeholder="Ciclo"
        onChange={(e) => setReportCycle(e.target.value)}
        options={Array(12)
          .fill(0)
          .map((_, idx) => {
            const momObj = moment()
              .add(idx - 6, 'month')
              .format('YYYYMM')
            return {
              label: momObj,
              value: momObj,
            }
          })}
      />

      <SelectableList
        searchable
        title="Turmas"
        djangoApp="activities"
        djangoModel="activity-class"
        itemComponent={ActivityClass}
        search={{
          is_bookable_class: false,
        }}
        selected={selected}
        setSelected={(value) =>
          setSelected((prev) =>
            typeof value === 'function' ? value(prev) : value
          )
        }
        showSelectAll
      />
      <Button
        className="mt-3"
        fullWidth
        variant="contained"
        color="primary"
        onClick={() => printFilterResults()}
      >
        Confirmar
      </Button>
    </>
  )
}
const School = () => {
  const showModal = useModal()
  const history = useHistory()

  const printFilter = () =>
    showModal({
      title: 'Selecione as turmas',
      children: <SchoolAttendanceListFilters />,
    })

  return (
    <>
      <div className="gym-dashboard m-sm-30 mt-6">
        <UserTypeSpecific type={[USER_TYPE.MANAGER, USER_TYPE.ADMINISTRATOR]}>
          <Grid container spacing={3} className="mt-5">
            <Grid item xs={12} md={4}>
              <KPICard
                app="activities"
                name="activity-classes-count"
                itemComponent={ActivityClass}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <KPICard
                app="activities"
                name="activity-classes-users"
                itemComponent={ActivityClassUser}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <KPICard
                app="activities"
                name="activity-classes-billing-users"
                itemComponent={ActivityClassUser}
              />
            </Grid>
          </Grid>
          <Divider className="my-5" />
        </UserTypeSpecific>
        <SimpleList
          searchable
          showPagination
          title="Modalidades"
          djangoApp="activities"
          djangoModel="activity-modality"
          search={{
            active: true,
            order_by: 'description',
          }}
          paginateBy={5}
          itemComponent={ActivityModality}
        />
      </div>

      <DjangoList
        djangoApp="activities"
        djangoModel="activity-class"
        headers={{
          description: 'Descrição',
          legacy_id: 'Id Legado',
          activity_modality: 'Modalidade',
          teacher: 'Professor',
          week_day: 'Dia',
          physical_space: 'Espaço Físico',
          max_number_of_users: 'Quantidade Máxima de Usuários',
          users_list: 'Lista de Alunos',
        }}
        additionalHeaderActions={(row) => (
          <>
            {!row.has_password && row.user_type !== 'lead' && (
              <>
                <ActionButton
                  id={row.id}
                  iconName="content_paste_search_icon"
                  title="modalidate"
                  onClick={() =>
                    history.push(
                      `/custom-report?app=activities&model=activity-class-user`
                    )
                  }
                />
                <ActionButton
                  id={row.id}
                  iconName="print"
                  title="Imprimir chamadas"
                  onClick={() => printFilter()}
                />
              </>
            )}
          </>
        )}
        initialFilters={{
          is_bookable_class: false,
        }}
        fields={[
          'description',
          'legacy_id',
          'activity_modality',
          'teacher',
          'week_day',
          'physical_space',
          'max_number_of_users',
          'actions',
        ]}
        customFields={{
          description: ({ id, description }) => (
            <TableCell className="pl-3 text-primary">
              <b>
                <Link to={`/activity-class-details/${id}`}>{description}</Link>
              </b>
            </TableCell>
          ),
          week_day: ({ week_day }) => (
            <TableCell className="t-table-cell-user-info pl-3">
              {moment()
                .day(week_day + 1 || 0)
                .format('dddd')}
            </TableCell>
          ),
        }}
      />
    </>
  )
}

export default School
