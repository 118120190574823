import { useCallback, useEffect, useState } from 'react'
import debounce from 'debounce-promise'

const useRequest = (method, loadingKey = null, prefetchParameters = null) => {
  // const [startLoading, stopLoading] = useLoader();

  const [prefetchSent, setPrefetchSent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)

  const send = useCallback(
    debounce((...params) => {
      setLoading(true)
      // if (loadingKey) startLoading(loadingKey);
      return new Promise((resolve, reject) =>
        method(...params)
          .then(
            (res) => {
              if (res) {
                setResponse(res)
                setError(null)
                resolve(res)
              } else {
                const error = new Error('No response found')
                setResponse(null)
                setError(error)
                reject(error)
              }
            },
            (err) => {
              setResponse(null)
              setError(err)
              reject(err)
            }
          )
          .finally(() => {
            setLoading(false)
            // if (loadingKey) stopLoading(loadingKey);
          })
      )
    }, 300),
    [method, setResponse, setError]
  )

  const clear = useCallback(() => {
    setResponse(null)
  }, [setResponse])

  const refresh = useCallback(() => {
    send(...prefetchParameters)
  }, [prefetchParameters, send])

  useEffect(() => {
    if (!prefetchSent && Array.isArray(prefetchParameters)) {
      setPrefetchSent(true)
      send(...prefetchParameters)
    }
  }, [prefetchSent, prefetchParameters, setPrefetchSent, send])

  return {
    error,
    response,
    isLoading,
    send,
    clear,
    refresh,
  }
}

export default useRequest
