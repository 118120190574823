import React, { useCallback, useMemo } from 'react'
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from '@material-ui/core'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

const ListTable = ({
  data,
  fields: propFields,
  headers,
  customCells,
  customHeaderCells,
  striped,
  reorderable,
  onReorder,
  emptyStateText,
}) => {
  const fields = useMemo(
    () => propFields || Object.keys(data?.[0] || {}),
    [propFields] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const tableEntries = useMemo(
    () =>
      data?.map((entry, idx) =>
        fields?.map((f, idx) => {
          const fieldKey = typeof f == 'object' ? f.key : f
          const fieldProps =
            typeof f == 'object' ? { ...f, key: undefined } : {}

          const CustomCell = customCells?.[fieldKey]

          if (CustomCell) return <CustomCell key={idx} {...entry} idx={idx} />
          else
            return (
              <TableCell key={idx} className="px-3 py-2" {...fieldProps}>
                {entry[fieldKey]}
              </TableCell>
            )
        })
      ),
    [fields, data] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const rows = useMemo(
    () => tableEntries?.map((e, i) => <TableRow key={i}>{e}</TableRow>),
    [tableEntries]
  )

  const headersRow = useMemo(
    () =>
      fields?.map((f, idx) => {
        const fieldKey = typeof f == 'object' ? f.key : f
        const fieldProps = typeof f == 'object' ? { ...f, key: undefined } : {}

        const CustomCell = customHeaderCells?.[fieldKey]

        if (CustomCell) return <CustomCell key={idx} />
        else
          return (
            <TableCell key={idx} className="px-3 py-2" {...fieldProps}>
              {(headers?.[fieldKey] || fieldKey).toTitleCase()}
            </TableCell>
          )
      }),
    [fields, headers] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleReorder = useCallback(
    (result) => {
      if (!result.destination) return

      onReorder(result.source.index, result.destination.index)
    },
    [onReorder]
  )

  return (
    <Table>
      <TableHead className="bold">
        <TableRow>{headersRow}</TableRow>
      </TableHead>
      {reorderable ? (
        <DragDropContext onDragEnd={handleReorder}>
          <Droppable droppableId="table">
            {(provided, snapshot) => (
              <TableBody
                className={
                  striped && !snapshot.isDraggingOver
                    ? 'table-striped'
                    : 'table-not-striped'
                }
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {tableEntries?.map((r, i) => (
                  <Draggable draggableId={i.toString()} index={i}>
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {r}
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <TableBody>
          {rows?.length === 0 && emptyStateText ? (
            <TableCell colSpan={fields.length}>
              <p className="text-center">{emptyStateText}</p>
            </TableCell>
          ) : (
            rows
          )}
        </TableBody>
      )}
    </Table>
  )
}

export default ListTable
