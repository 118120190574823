import React, { createContext, useState } from 'react'

import merge from 'lodash/merge'

import {
  MatxLayoutSettings,
  themeVariants,
} from 'app/components/MatxLayout/settings'

const SettingsContext = createContext({
  settings: MatxLayoutSettings,
  updateSettings: () => {},
})

export const SettingsProvider = ({
  settings = MatxLayoutSettings,
  children,
}) => {
  const activeThemeConfig = themeVariants.find(({ rule }) =>
    rule.test(window.location.hostname)
  )
  settings.activeTheme =
    process.env.REACT_APP_THEME_NAME || activeThemeConfig?.theme
  const [currentSettings, setCurrentSettings] = useState(settings)

  const handleUpdateSettings = (update = {}) => {
    const marged = merge({}, currentSettings, update)
    setCurrentSettings(marged)
  }

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        updateSettings: handleUpdateSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsContext
