import { useDismissModal } from 'app/ducks/modal/hooks'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import useTimer from './useTimer'

const useModalTimer = (time) => {
  const dismissModal = useDismissModal()
  const timer = useTimer(time)

  useEffect(() => {
    if (!timer.finished) return

    dismissModal()
    toast.error('Seu código foi expirado')
  }, [timer, dismissModal])

  return timer
}

export default useModalTimer
