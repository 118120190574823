import React, { useEffect, useState, useCallback } from 'react'
import useRequest from 'app/hooks/useRequest'
import HttpHandler from 'app/reusable/HttpHandler'
import ListTable from 'app/components/Tables/ListTable'
import { CircularProgress } from '@material-ui/core'
import { Pagination } from '@mui/material'

const KPIModal = ({ app, name, itemComponent: ItemComponent, params = {} }) => {
  const [page, setPage] = useState(1)
  const kpiRequest = useRequest(HttpHandler.kpi, null)

  const kpiData = kpiRequest.response
  const kpiPaginator = kpiData?.paginator

  const search = useCallback(() => {
    kpiRequest.send(app, name, 'list', { ...params, page, paginate_by: 10 })
    // eslint-disable-next-line
  }, [page])

  // eslint-disable-next-line
  useEffect(() => search(), [page])

  if (kpiData === null) return <CircularProgress color="secondary" size={20} />

  return (
    <>
      {ItemComponent ? (
        kpiData?.data?.map((i) => <ItemComponent {...i} />)
      ) : (
        <ListTable
          striped
          headers={kpiData?.header}
          fields={Object.keys(kpiData?.header)}
          data={kpiData?.data}
        />
      )}
      {kpiPaginator?.last_page_number > 1 && (
        <Pagination
          boundaryCount={3}
          page={kpiPaginator?.current_page_number}
          count={kpiPaginator?.last_page_number}
          onChange={(_, page) => setPage(page)}
          className="mt-5"
        />
      )}
    </>
  )
}

export default KPIModal
