import React from 'react'
import { appendRequired } from 'app/reusable/utils'

import moment from 'moment-timezone'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const TIME_ZONE = 'America/Sao_Paulo'

const DateTimePickerInput = ({
  name,
  value,
  field,
  errors,
  onRawChange,
  testingClassName,
}) => {
  const isInvalid = !!(
    errors &&
    (!value || !moment.tz(value, TIME_ZONE).isValid())
  )
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <div className={testingClassName}>
        <DateTimePicker
          timezone={TIME_ZONE}
          variant="outlined"
          label={appendRequired(field.label, field.required)}
          value={value ? moment.tz(value, TIME_ZONE) : undefined}
          inputFormat="DD/MM/YYYY HH:mm"
          onChange={(value) => {
            onRawChange(
              name,
              value
                ? moment.tz(value, TIME_ZONE).seconds(0).toISOString()
                : value
            )
          }}
          slotProps={{
            textField: {
              error: isInvalid,
              variant: 'outlined',
              size: 'small',
              fullWidth: true,
            },
            popper: {
              className: 'fix-datepicker-scroll',
            },
          }}
          // TODO: Figure out where they moved the clearable option to
          // clearable={!field.required}
          // clearText="Limpar"
          cancelText="Cancelar"
        />
        {isInvalid && (
          <small className="text-error text-bold">
            A data e hora é inválida.
          </small>
        )}
      </div>
    </LocalizationProvider>
  )
}

export default DateTimePickerInput
