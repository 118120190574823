import { themeColors } from './themeColors'
import { createTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale'
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'
import themeOptions from './themeOptions'

function createMatxThemes() {
  let themes = {}

  forEach(themeColors, (value, key) => {
    themes[key] = createTheme(
      merge({}, themeOptions.default, themeOptions[key] || {}, value),
      ptBR
    )
  })
  return themes
}

export const themes = createMatxThemes()
