import React from 'react'
import { useHasPermission } from 'app/ducks/auth/hooks'
import { ActionButton } from 'app/components/reusable/DjangoList'
import { useModalForm } from 'app/ducks/modal/hooks'
import useDeleteRecord from 'app/hooks/useDeleteRecord'

const ModelActions = ({
  app,
  model,
  id,
  showDelete = true,
  showEdit = true,
  onDelete: propsOnDelete,
}) => {
  const showModalForm = useModalForm()
  const deleteRecord = useDeleteRecord()
  const hasChangePermission = useHasPermission(model, 'change')
  const hasDeletePermission = useHasPermission(model, 'delete')

  const onChange = (e) => {
    e.preventDefault()
    e.stopPropagation()
    showModalForm({ app, model, id })
  }

  const onDelete =
    propsOnDelete ||
    ((e) => {
      e.preventDefault()
      e.stopPropagation()
      deleteRecord({ app, model, id })
    })
  if (!id) return null

  return (
    <>
      {hasChangePermission && showEdit && (
        <ActionButton
          color="primary"
          iconName="create"
          className="ml-3"
          onClick={onChange}
        />
      )}
      {hasDeletePermission && showDelete && (
        <ActionButton
          color="primary"
          iconName="delete"
          className="ml-3"
          onClick={onDelete}
        />
      )}
    </>
  )
}

export default ModelActions
