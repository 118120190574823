import React from 'react'
import { Button, Icon } from '@material-ui/core'
import { useDjangoFormContext } from 'app/hooks/useDjangoForm'
import * as FormComponents from './components'

const FormField = ({
  name,
  onSuccess,
  embeddedFormCustomAdd,
  embeddedFormIsReorderable,
}) => {
  const {
    data,
    fields,
    handleFieldChange,
    handleRawFieldChange,
    handleSubmit,
    errors,
    loading,
  } = useDjangoFormContext()

  if (name === 'submit_button')
    return (
      <Button
        onClick={() => handleSubmit(onSuccess)}
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
      >
        Salvar &nbsp;
        <Icon>save</Icon>
      </Button>
    )

  const field = fields?.[name]
  if (!field || field?.widget.is_hidden) return null

  const FieldComponent =
    {
      email: FormComponents.TextInput,
      text: FormComponents.TextInput,
      number: FormComponents.NumberInput,
      date: FormComponents.DatePicker,
      time: FormComponents.TimePicker,
      datetime: FormComponents.DateTimePicker,
      checkbox: FormComponents.Switch,
      select: FormComponents.Select,
      selectmultiple: FormComponents.Select,
      file: FormComponents.FileField,
      camera: FormComponents.Camera,
      color: FormComponents.Color,
      embeddedform: FormComponents.EmbeddedForm,
    }[field.widget.input_type] || FormComponents.TextInput

  return (
    <FieldComponent
      name={name}
      field={field}
      value={data?.[name]}
      onChange={handleFieldChange}
      testingClassName={`t-field-${name.replace(/_/g, '-')}`}
      onRawChange={handleRawFieldChange}
      errors={errors[name]}
      embeddedFormCustomAdd={embeddedFormCustomAdd}
      embeddedFormIsReorderable={embeddedFormIsReorderable}
    />
  )
}

export default FormField
